import Vue from 'vue';
import axios from 'axios';
import store from "../store";

/**
 * Функция-хелпер для использования в модуле (в том числе в мутациях).
 * @param channel
 * @returns {string}
 */
function channelKey(channel) {
    return channel.type + '_' + channel.id;
}

export const moduleChat = {
    namespaced: true,

    state: {
        //isActive: false,
        channels: [],
        authors: [],
        channel: null, // Объект с идентификатором и типом только, полный объект через геттер
        windowChannels: [], // Массив объектов с идентификатором и типом только, полные объекты через геттер
        messages: {}, // Массив сообщений по открытым каналам
        typing: {},
        usersOnline: {},
        isSimpleMode: false,
        newMessagesProcessDate: null,
        newMessagesLastDate: null,
        newMessagesInterval: 30000,
        newMessagesSimpleInterval: 2500,
        newMessagesIsLoading: false,
        processNewMessagesStarted: false // Признак, что обновление работает
    },
    getters: {
        /**
         * Если хелпер будет нужен в другом месте.
         * @param state
         * @returns {function(*=): string}
         */
        channelKey: () => (channel) => {
            return channelKey(channel);
        },

        // isActive: (state, getters) => {
        //     return getters.channelsIsActive && state.isActive;
        // },
        channelsIsActive: (state) => {
            return state.channels.length !== 0;
        },
        channels: (state) => {
            return state.channels;
        },
        // authors: (state) => {
        //     return state.authors;
        // },
        // Открытый канал - вероятно, пригодится для полной версии чата
        channel: (state) => {
            if (state.channel) {
                let currentChannel = state.channels.find(channel => state.channel.id.toString() === channel.id.toString() && state.channel.type === channel.type);
                if (currentChannel) {
                    return currentChannel;
                }
            }
            return null;
        },
        // Окна с открытыми каналами
        windowChannels: (state) => {
            let windowChannels = [];
            if (state.windowChannels) {
                state.windowChannels.forEach(function (windowChannel) {
                    let currentChannel = state.channels.find(channel => windowChannel.id.toString() === channel.id.toString() && windowChannel.type === channel.type);
                    if (currentChannel) {
                        windowChannels.push(currentChannel);
                    }
                });
            }
            return windowChannels;
        },
        /**
         * Упрощенный список открытых каналов (полная версия чата и окна)
         */
        simpleOpenedChannels: (state) => {
            let opened = [];

            if (state.windowChannels) {
                state.windowChannels.forEach(function (windowChannel) {
                    opened.push(windowChannel);
                });
            }

            if (state.channel && !opened.find(channel => channelKey(channel) === channelKey(state.channel))) {
                opened.push(state.channel);
            }

            return opened;
        },
        messages: (state) => (channel) => {
            return state.messages[channelKey(channel)];
        },
        /**
         * Является ли заданный канал текущим. Проверяется по идентификатору и типу.
         * @param state
         * @returns {function(*): (*|null|boolean)}
         */
        isChatChannel: (state) => (channel) => {
            return state.channel && state.channel.id.toString() === channel.id.toString() && state.channel.type === channel.type;
        },

        /**
         * Проверяет существование чата по типу и ид, возвращает канал, если всё ок.
         * @param state
         * @returns {function(*): (*|boolean)}
         */
        findChannel: (state) => (channelObj) => {
            return state.channels.find(channel => channelObj.id.toString() === channel.id.toString() && channelObj.type === channel.type);
        },

        /**
         * Есть ли вообще такой канал
         * @param state
         * @returns {Function}
         */
        // hasChannel: (state) => (channelObject) => {
        //     if (state.channels) {
        //         return state.channels.findIndex(channel => channelObject.id === channel.id && channelObject.type === channel.type) !== -1;
        //     }
        //     return false;
        // },

        /**
         * Открыто ли окно чата выбранного канала. Проверяется по идентификатору и типу.
         * @param state
         * @returns {function(*): (*|null|boolean)}
         */
        isWindowChannel: (state) => (windowChannel) => {
            return state.windowChannels && !!state.windowChannels.find(channel => windowChannel.id.toString() === channel.id.toString() && windowChannel.type === channel.type);
        },

        author: (state, getters, rootState, rootGetters) => (id, type) => {
            if (type === 'bot') {
                return {
                    id: 'bot',
                    type: 'bot'
                };
            }else if (type === 'master') {
                return {
                    avatar: rootGetters['rpg/serverUrl'] + rootGetters['settings/setting']('masterAvatar'),
                    avatarSmall: rootGetters['rpg/serverUrl'] + rootGetters['settings/setting']('masterAvatar'),
                    id: 'master',
                    image: rootGetters['rpg/serverUrl'] + rootGetters['settings/setting']('masterAvatar'),
                    name: rootGetters['rpg/trans']('master.comment_name'),
                    type: 'master'
                };
            } else {
                if (rootGetters['rpg/charactersKeys']) {
                    var charId = id;

                    if (type === 'user' && rootGetters['auth/getUser'] && id === rootGetters['auth/getUser'].id) {
                        // Если писал пользователь и инфа о нем есть в персонажах, меняем ключ на user и берем оттуда ниже
                        if (rootGetters['rpg/hasCharacter']('user')) {
                            charId = 'user';
                        } else {
                            // Иначе - возьмём из Auth
                            return {
                                avatar: rootGetters['auth/getUser'].avatar,
                                avatarSmall: rootGetters['auth/getUser'].avatar,
                                id: rootGetters['auth/getUser'].id,
                                image: rootGetters['auth/getUser'].avatar,
                                name: rootGetters['auth/getUser'].name,
                                type: 'user'
                            };
                        }
                    }

                    if ((type === 'profile' || charId === 'user') && rootGetters['rpg/hasCharacter'](charId)) {
                        // Составляем сборную
                        return Object.assign({
                            id: id,
                            type: type,
                        }, rootGetters['rpg/getCharacter'](charId));
                    }
                }

                let foundAuthor = state.authors.find(author => author.id === id && author.type === type);

                if (foundAuthor) {
                    return foundAuthor;
                }
            }

            return null;
        },

        authorIsCurrentChar: (state, getters, rootState, rootGetters) => (id, type) => {
            if (rootGetters['rpg/currentCharacter']) {
                switch (rootGetters['rpg/currentCharacter'].key) {
                    case 'master':
                        return type === 'master';
                    case 'user':
                        return rootGetters['auth/isAuth'] && id === rootGetters['auth/getUser'].id;
                    default:
                        return id === rootGetters['rpg/currentCharacter'].key;
                }
            }
            return false;
        },

        typing: (state) => (channel) => {
            let names = [];
            if (state.typing[channelKey(channel)]) {
                let now = Date.now(),
                    typings = state.typing[channelKey(channel)],
                    allNames = Object.keys(typings);
                allNames.forEach(function (name) {
                    if (typings[name] + 1000 > now) {
                        names.push(name);
                    }
                });
            }
            return names;
        },

        usersOnline: (state) => (channel) => {
            return state.usersOnline[channelKey(channel)];
        },

        simpleModeStarted: (state) => {
            return state.isSimpleMode;
        },

        processNewMessagesStarted: (state) => {
            return state.processNewMessagesStarted;
        },

        /**
         * Признак того, что нужно загрузить новые сообщения.
         * Текущее время - из-за кеширования (не реактивно из Date.now()).
         * @param state
         * @returns {function(*): *}
         */
        needNewMessages: (state) => (currentTime) => {
            return !state.newMessagesProcessDate || (currentTime - state.newMessagesProcessDate > (state.isSimpleMode ? state.newMessagesSimpleInterval : state.newMessagesInterval));
        },

        /**
         * Дата для загрузки новых сообщений (загружать сообщения, добавленные после этой даты).
         * @param state
         * @returns {null}
         */
        newMessagesLastDate: (state) => {
            return state.newMessagesLastDate;
        },

        newMessagesIsLoading: (state) => {
            return state.newMessagesIsLoading;
        },

        unreadCount: (state) => {
            if (!state.channels.length) {
                return 0;
            }
            var count = 0;
            state.channels.forEach(channel => count += channel.unreads);
            return count;
        }
    }
    ,
    // getters: {
    //     trans: (state) => (key) => {
    //         return key.split('.').reduce((o,i)=>o[i], state);
    //     },
    // },
    mutations: {
        // setLang (state, payload) {
        //     Object.keys(payload).forEach(function (lang) {
        //         Vue.set(state, lang, payload[lang]);
        //     });
        // }
        // setActive (state, isActive) {
        //     state.isActive = isActive;
        // },
        setChannels (state, channels) {
            state.channels = channels;
            // Каждый раз при обновлении каналов надо бы чистить выделенные каналы
            state.windowChannels = [];

            // Проверяем. Список может быть загружен позже, чем выбран канал. Если он выбран, очищаем список непрочитанных
            if (state.channel && state.channels.length) {
                let channel = state.channels.find(channel => state.channel.id.toString() === channel.id.toString() && state.channel.type === channel.type);
                if (channel) {
                    channel.unreads = 0;
                }
            }
        },
        setChannel (state, channelObject) {
            state.channel = channelObject ? { id: channelObject.id, type: channelObject.type } : null;

            if (state.channels.length && channelObject) {
                let channel = state.channels.find(channel => channelObject.id.toString() === channel.id.toString() && channelObject.type === channel.type);
                if (channel) {
                    channel.unreads = 0;
                }
            }
        },
        addWindowChannel (state, channelObject) {
            if (channelObject) {
                state.windowChannels.push({ id: channelObject.id, type: channelObject.type });

                if (state.channels.length) {
                    let channel = state.channels.find(channel => channelObject.id.toString() === channel.id.toString() && channelObject.type === channel.type);
                    if (channel) {
                        channel.unreads = 0;
                    }
                }
            }
        },
        removeWindowChannel (state, channelObject) {
            if (channelObject) {
                let index = state.windowChannels.findIndex(channel => channelObject.id === channel.id && channelObject.type === channel.type);
                if (index !== -1) {
                    state.windowChannels.splice(index, 1);
                }
            }
        },
        addAuthors (state, authors) {
            state.authors = state.authors.concat(authors);
        },
        setMessages (state, payload) {
            if (!state.messages[channelKey(payload.channel)]) {
                Vue.set(state.messages, channelKey(payload.channel), []);
            }

            // Создаем массив с уникальными и отсортированными элементами
            // Сначала добавленные новые - на случай обновления. Обычно эта функция вызывается при прокрутке чата вверх.
            // Всё-таки если в чат свежее сообщение приходит, оно через addMessage идёт обычно
            var messages = payload.messages.concat(state.messages[channelKey(payload.channel)]);
            messages = messages.filter((item, index) => messages.findIndex(message => message.id === item.id) === index);

            // Сортируем от старых к новым
            messages = messages.sort((a, b) => {
                let comparison = 0;
                if (a.date > b.date) {
                    comparison = 1;
                } else if (a.date  < b.date) {
                    comparison = -1;
                }
                return comparison;
            });

            Vue.set(state.messages, channelKey(payload.channel), messages);
        },
        addMessage (state, payload) {
            if (!state.messages[channelKey(payload.channel)]) {
                Vue.set(state.messages, channelKey(payload.channel), []);
            }
            let index = state.messages[channelKey(payload.channel)].findIndex(message => payload.message.id === message.id);
            if (index === -1) {
                state.messages[channelKey(payload.channel)].push(payload.message)
            }

            // Проверяем, нужно ли добавлять в канал единичку нового сообщения
            if ( state.channels
                && (!state.channel || channelKey(state.channel) !== channelKey(payload.channel))
                && (!state.windowChannels.length || !state.windowChannels.find(channel => channelKey(channel) === channelKey(payload.channel)))
            ) {
                let channel = state.channels.find(channel => channelKey(channel) === channelKey(payload.channel));
                if (channel) {
                    channel.unreads++;
                }
            }
        },
        updateMessage (state, payload) {
            if (!state.messages[channelKey(payload.channel)]) {
                Vue.set(state.messages, channelKey(payload.channel), []);
            }
            let index = state.messages[channelKey(payload.channel)].findIndex(message => payload.message.id === message.id);
            if (index !== -1) {
                if (payload.delete) {
                    state.messages[channelKey(payload.channel)].splice(index, 1);
                } else {
                    state.messages[channelKey(payload.channel)].splice(index, 1, payload.message);
                }
            }
        },
        /**
         * Очищаем сообщения полностью.
         * @param state
         */
        freshMessages (state) {
            state.messages = {};
        },
        setTyping (state, payload) {
            if (!state.typing[channelKey(payload.channel)]) {
                Vue.set(state.typing, channelKey(payload.channel), {});
            }
            state.typing[channelKey(payload.channel)][payload.name] = payload.time;
        },

        setUsersOnline (state, payload) {
            if (!state.usersOnline[channelKey(payload.channel)]) {
                Vue.set(state.usersOnline, channelKey(payload.channel), []);
            }

            var usersOnline = payload.users.concat(state.usersOnline[channelKey(payload.channel)]);
            usersOnline = usersOnline.filter((item, index) => usersOnline.findIndex(user => user.id === item.id) === index);

            Vue.set(state.usersOnline, channelKey(payload.channel), usersOnline);
        },

        addUserOnline (state, payload) {
            if (!state.usersOnline[channelKey(payload.channel)]) {
                Vue.set(state.usersOnline, channelKey(payload.channel), []);
            }
            let index = state.usersOnline[channelKey(payload.channel)].findIndex(user => payload.user.id === user.id);
            if (index === -1) {
                state.usersOnline[channelKey(payload.channel)].push(payload.user)
            }
        },

        removeUserOnline (state, payload) {
            if (!state.usersOnline[channelKey(payload.channel)]) {
                Vue.set(state.usersOnline, channelKey(payload.channel), []);
            }
            let index = state.usersOnline[channelKey(payload.channel)].findIndex(user => payload.user.id === user.id);
            if (index !== -1) {
                state.usersOnline[channelKey(payload.channel)].splice(index, 1);
            }
        },

        startSimpleMode (state) {
            state.isSimpleMode = true;
        },

        startProcessNewMessages (state) {
            state.processNewMessagesStarted = true;
        },

        /**
         * Установить дату для загрузки новых сообщений
         * @param state
         * @param payload
         */
        setNewMessagesLastDate (state, payload) {
            state.newMessagesLastDate = payload;
        },

        /**
         * Установить дату обработки запроса на загрузку новых сообщений.
         * Если не передавать, загрузится в следующую же секунду.
         * @param state
         * @param payload
         */
        setNewMessagesProcessDate (state, payload = null) {
            state.newMessagesProcessDate = payload;
        },

        setNewMessagesIsLoading (state, payload) {
            state.newMessagesIsLoading = payload;
        }
    },
    actions: {
        fetchChannels({ dispatch, commit, getters, rootGetters }) {
            if (rootGetters['rpg/currentGame'] && rootGetters['rpg/currentCharacter']) {
                axios.get('/api/rpg/chat/channels/' + rootGetters['rpg/currentGame'].key + '/' + rootGetters['rpg/currentCharacter'].key)
                    .then((response) => {
                        //this.channels = response.data;
                        if (response.data) {
                            commit('setChannels', response.data);
                            // commit('setChannel', 0);
                            // dispatch('fetchMessages');

                            // для всех каналов - включаем прослушивание
                            // Пока так
                            // todo - подумать об этом, когда их будет много, когда будут личные
                            getters.channels.forEach(function (channel) {
                                dispatch('listen', channel);
                            });
                        }
                        //this.channel = response.data[0];
                        // this.user = response.data[0]
                        // this.fetchAuthUser()
                        // Может тут всё-таки запрашивать?
                        //this.fetchMessages();

                    })
                    .catch((error) => {
                        let message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                        dispatch('loading/error', message, { root: true });
                    });
            }
        },
        fetchAuthors: function ({ dispatch, commit, getters, rootGetters }, payload) {
            let ids = {},
                messages = payload.hasOwnProperty('messages') ? payload.messages : getters.messages(payload.channel);

            messages.forEach(function (message) {
                // Только если ранее не было
                if (!getters.author(message.author_id, message.author_type) && !(ids[message.author_type] && ids[message.author_type].indexOf(message.author_id) !== -1)) {
                    switch (message.author_type) {
                        case 'user':
                        case 'profile':
                            if (!ids[message.author_type]) {
                                ids[message.author_type] = [];
                            }
                            ids[message.author_type].push(message.author_id);
                            break;
                    }
                }
            });

            if (ids.user || ids.profile) {
                axios.post('/api/rpg/chat/authors/' + rootGetters['rpg/currentGame'].key + '/' + rootGetters['rpg/currentCharacter'].key + '/' + payload.channel.type + '/' + payload.channel.id, ids).then((response) => {
                    //this.authors = response.data;
                    commit('addAuthors', response.data);
                    //this.user = response.data[0]
                    //this.fetchAuthUser()
                    //this.fetchMessages();
                    //dispatch('fetchMessages');
                }).catch((error) => {
                    let message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                    dispatch('loading/error', message, { root: true });
                });
            }
        },

        fetchMessages: function ({ dispatch, commit, getters, rootGetters }, payload) {
            if (payload.channel) {
                var idUrlPart = '';
                if (payload.id) {
                    idUrlPart = '/' + payload.id;
                }

                axios.get('/api/rpg/chat/messages/' + rootGetters['rpg/currentGame'].key + '/' + rootGetters['rpg/currentCharacter'].key + '/' + payload.channel.type + '/' + payload.channel.id + idUrlPart).then((response) => {
                    //this.messages = response.data;
                    commit('setMessages', { channel: payload.channel, messages: response.data });
                    dispatch('fetchAuthors', { channel: payload.channel });
                    //this.assignMessages();
                    if (payload.callback) {
                        payload.callback(response.data && response.data.length > 0); // Вызываем колбэк с признаком наличия данных в ответе
                    }
                }).catch((error) => {
                    let message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                    dispatch('loading/error', message, { root: true });
                    if (payload.callback) {
                        payload.callback(); // Вызываем колбэк
                    }
                });
            }
        },
        // toogleActive(context) {
        //     context.commit('setActive', !context.getters.isActive);
        // },
        closeChat({ dispatch, commit, getters, rootGetters }, channel) {
            //commit('setActive', false);
            // Делаем канал неактивным
            //commit('setChannel', null);
            commit('removeWindowChannel', channel);
        },
        openChat({ dispatch, commit, getters, rootGetters }, channel) {
            //commit('setActive', true);
            commit('addWindowChannel', channel);

            // todo - загрузить сообщения чата
            dispatch('fetchMessages', { channel: channel });
            // Запрашиваем онлайн - для отметки "Прочитано" в каналах
            dispatch('rpg/getOnlineNow', null, { root: true });
        },

        /**
         * Включить прослушивание сокетов на нужном канале.
         */
        listen({ dispatch, commit, getters, rootGetters }, channel) {
            // Прописываем в опции игру и персонажа
            // Надо в коннектор добавлять уже =) А не в основные опции
            Echo.connector.options.auth.headers['game'] = rootGetters['rpg/currentGame'] ? rootGetters['rpg/currentGame'].key : null;
            Echo.connector.options.auth.headers['character'] = rootGetters['rpg/currentCharacter'] ? rootGetters['rpg/currentCharacter'].key : null;

            Echo.join(`chat.rpg.${channel.type}.${channel.id}`)
                .here((users) => {
                    //console.log(users);
                    commit('setUsersOnline', { channel: channel, users: users });
                })
                .joining((user) => {
                    // todo
                    //console.log(user);
                    commit('addUserOnline', { channel: channel, user: user });
                })
                .leaving((user) => {
                    // todo
                    //console.log(user);
                    commit('removeUserOnline', { channel: channel, user: user });
                })
                .listen('ChatMessageSent', event => {
                    //console.log(event);
                    //this.messages.push(event.message)
                    commit('addMessage', { channel: channel, message: event.message });
                    // Обновляем авторов, вдруг новый кто-то написал?
                    dispatch('fetchAuthors', { channel: channel });
                    //this.assignMessages()
                    // Запрашиваем онлайн - для отметки "Прочитано" в каналах
                    dispatch('rpg/getOnlineNow', null, { root: true });
                })
                .listen('ChatMessageDeleted', event => {
                    //this.fetchMessages()
                    //console.log(event);
                    commit('updateMessage', { channel: channel, message: event.message, delete: true });
                    //dispatch('fetchMessages', { channel: channel });
                })
                .listen('ChatMessageUpdated', event => {
                    //this.fetchMessages()
                    //console.log(event);
                    commit('updateMessage', { channel: channel, message: event.message });
                    //dispatch('fetchMessages', { channel: channel });
                })
                .listenForWhisper('typing', (e) => {
                    dispatch('setTyping', Object.assign(e, { channel: channel }));
                });

            // Через 5 секунд проверяем, не нужно ли включить простой режим
            setTimeout(() => {
                if (!Echo.connector.socket.connected && !getters.simpleModeStarted) {
                    console.log('Включаем упрощенный режим чата, хур-хур, эт самое...');
                    commit('startSimpleMode');
                }
            }, 5000);
        },

        /**
         * Оповестить о том, что персонаж печатает в канале.
         */
        whisper({ dispatch, commit, getters, rootGetters }, channel) {
            Echo.join(`chat.rpg.${channel.type}.${channel.id}`)
                .whisper('typing', {
                    name: rootGetters['rpg/currentCharacter'].key === 'master' ? rootGetters['rpg/trans']('master.comment_name') : rootGetters['rpg/currentCharacter'].name
                });
        },


        /**
         * Выключить прослушивание сокетов на нужном канале.
         */
        leave({ dispatch, commit, getters, rootGetters }, channel) {
            Echo.leave(`chat.rpg.${channel.type}.${channel.id}`);
        },

        /**
         * Запускается для инициализации чата - после выбора игры и персонажа.
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         */
        initChat({ dispatch, commit, getters, rootGetters }) {
            //dispatch('listen');
            // todo - подключение к каналам - пока прямо там
            dispatch('fetchChannels');
            if (!getters.processNewMessagesStarted) {
                // Устанавливаем текущую дату на сообщения и запросы
                commit('setNewMessagesLastDate', Date.now());
                commit('setNewMessagesProcessDate', Date.now());
                // Запускается только один раз
                commit('startProcessNewMessages');
                dispatch('processNewMessages');
            }
        },

        /**
         * Запускается для очистки чата - после загрузки базовой информации.
         * @param dispatch
         * @param commit
         * @param getters
         * @param rootGetters
         */
        freshChat({ dispatch, commit, getters, rootGetters }) {
            //dispatch('listen');

            // Пока так
            // todo - подумать об этом, когда их будет много, когда будут личные
            getters.channels.forEach(function (channel) {
                dispatch('leave', channel);
            });

            // todo - отключение от каналов
            commit('setChannels', []);
            commit('freshMessages');
        },

        setTyping({ dispatch, commit, getters, rootGetters }, payload) {
            commit('setTyping', Object.assign(payload, { time: Date.now() }));
        },

        // Для большого чата
        setChannel({ dispatch, commit, getters, rootGetters }, channel) {
            commit('setChannel', channel);
        },

        unsetChannel({ dispatch, commit, getters, rootGetters }) {
            commit('setChannel', null);
        },

        processNewMessages({ dispatch, commit, getters, rootGetters }) {
            if (getters.needNewMessages(Date.now())) {
                commit('setNewMessagesProcessDate', Date.now());
                dispatch('fetchNewMessages');
            }
            // Цикл работает каждую секунду. При сворачивании тормозится, но в needNewMessages будет старая дата,
            // так что запустится сразу при открывании окна
            setTimeout(() => dispatch('processNewMessages'), 1000);
        },

        fetchNewMessages: function ({ dispatch, commit, getters, rootGetters }) {
            if (getters.channels.length && !getters.newMessagesIsLoading) {
                // Дата, после которой грузить сообщения
                let lastDate = Date.now();
                commit('setNewMessagesIsLoading', true);

                axios.post('/api/rpg/chat/messages/' + rootGetters['rpg/currentGame'].key + '/' + rootGetters['rpg/currentCharacter'].key + '/new', {
                    date: getters.newMessagesLastDate,
                    channels: getters.channels.map(channel => ({
                        id: channel.id,
                        type: channel.type
                    }))
                }).then((response) => {
                    response.data.forEach(channel => {
                        // Иногда приходит не массив, а объект?
                        var messages = channel.messages;
                        if (messages instanceof Object) {
                            messages = Object.values(messages);
                        }

                        if (messages.length) {
                            // Тут канал в реальности не такой объект, но нужны только два поля - id и type, и они есть
                            commit('setMessages', { channel: channel, messages: messages });
                            dispatch('fetchAuthors', { channel: channel });
                        }
                    })

                    commit('setNewMessagesLastDate', lastDate);
                }).catch((error) => {
                    let message = error instanceof Object && error.response && error.response instanceof Object &&  error.response.data ? (error.response.data instanceof Object && error.response.data.error ? error.response.data.error : error.response.data) : error;
                    dispatch('loading/error', message, { root: true });
                }).then(() => {
                    commit('setNewMessagesIsLoading', false);
                });
            }
        },

        /**
         * Обнуляет время последнего запроса в Простом режиме,
         * чтобы в следующую же секунду были запрошены новые сообщения.
         * Обычно нужно после отправки сообщения.
         * @param commit
         * @param getters
         */
        fetchNewMessagesNow: function ({ commit, getters }) {
            if (getters.simpleModeStarted) {
                commit('setNewMessagesProcessDate');
            }
        }
    }
};
