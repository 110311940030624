<template>
    <div class="container" v-if="comments.length">
        <h5>{{ $t('form.comments') }}</h5>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow">
            <div class="media text-muted pt-3" v-for="comment in comments">
                <img class="mr-2 rounded smallPhoto" :src="comment.avatar" :alt="comment.name" v-if="comment.avatar">
                <div class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <strong class="text-rpg-primary">
                            <a :href="comment.profileUrl" v-if="comment.profileUrl" v-html="comment.colorName"></a>
                            <span v-else>{{ comment.name }}</span>
                        </strong>
                        <small v-if="comment.createdAt">{{ comment.createdAt }}</small>
                    </div>
                    <span class="d-block" v-html="comment.body"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            comments: { default: () => [] }
        },
        name: "ProfileComments"
    }
</script>

<style scoped>

</style>
