<template>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" :disabled="disabled"></ckeditor>
</template>

<script>
    // As docs said, here are localizations import. All that we need for site.
    //import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
    //import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    // The editor creator to use.
    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

    import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
    //import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
    import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
    import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
    import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
    import Heading from '@ckeditor/ckeditor5-heading/src/heading';
    // import Image from '@ckeditor/ckeditor5-image/src/image';
    // import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
    // import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
    // import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
    // import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
    import Link from '@ckeditor/ckeditor5-link/src/link';
    import List from '@ckeditor/ckeditor5-list/src/list';
    import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

    import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
    import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave';

    export default {
        name: 'BasicCkeditor',
        components: {
            Ckeditor: CKEditor.component
        },
        props: {
            value: {
                required: true
            },
            name: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            locale: {
                type: String,
                default: 'ru'
            },
            saveFunction: {
                default: () => (data) => {
                    new Promise( resolve => {
                            //console.log( 'Saved', data );
                            resolve();
                        } )
                }
            }
        },
        watch: {
            editorData() {
                this.$emit('input', this.editorData);
            },
            value () {
                // Скедитор тут только инициализирует данные и дальше управляет сам. Чтобы их синхронизировать
                // при изменении модели, делаю такы
                if (this.value !== this.editorData) {
                    this.editorData = this.value;
                    //this.editor.setData(this.value);
                }
            }
        },
        data() {
            let currentObj = this;
            return {
                editor: ClassicEditor,
                editorData: this.value,
                editorConfig: {
                    plugins: [
                            Autosave,
                            Essentials,
                            //UploadAdapter,
                            Autoformat,
                            Bold,
                            Italic,
                            BlockQuote,
                            EasyImage,
                            Heading,
                            // Image,
                            // ImageCaption,
                            // ImageStyle,
                            // ImageToolbar,
                            // ImageUpload,
                            Link,
                            List,
                            Paragraph,
                            Alignment,                                                            // <--- ADDED
                            Underline,
                            Strikethrough
                    ],
                    //extraPlugins: [ Underline, Strikethrough ],
                    language: this.locale,
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'alignment',
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            'link',
                            'bulletedList',
                            'numberedList',
                            //'imageUpload',
                            'blockQuote',
                            'undo',
                            'redo'
                        ]
                    },

                    autosave: {
                        save( editor ) {
                            return currentObj.saveFunction( editor.getData() );
                        }
                    }

                    // image: {
                    //     // We need to configure the image toolbar, too, so it uses the new style buttons.
                    //     // toolbar: [ 'imageStyle:full', 'imageStyle:side', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageTextAlternative' ],
                    //     //
                    //     // styles: [
                    //     //     'full',
                    //     //     'side',
                    //     //     'alignLeft',
                    //     //     'alignCenter',
                    //     //     'alignRight'
                    //     // ]
                    // }
                }
            };
        },
        computed: {
            // For the 'empty' ckeditor display
            // nativeValue () {
            //     if (this.editorData === '<p>&nbsp;</p>') return null;
            //     return this.editorData;
            // }
        }
    }
</script>
