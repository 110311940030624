<template>
    <draggable-panel
            no-body
            :resizable="true"
            :w="270"
            :h="300"
            :minWidth="270"
            :minHeight="220"
            :x="460"
            :y="50"
            :onDragStart="onDragStartCallback"
            :is-active="true"
    >
        <b-card ref="chatCard"
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="h-100 chat-window"
        >
            <header slot="header" ref="chatHeader">
                <b class="card-header-title">{{ channel ? channel.title : trans('chat.title') }}</b>
                <b-button pill type="button" class="close" aria-label="Close" @click="closePanel()">
                    <font-awesome-icon icon="times" style="color: #fff;"></font-awesome-icon>
                </b-button>
            </header>

            <vue-perfect-scrollbar v-chat-scroll="{always: false, smooth: false}" class="card-body msg_history" id="chatScroll" ref="chatScroll">
                <infinite-loading direction="top" @infinite="loadMore" forceUseInfiniteWrapper="#chatScroll">
                    <div slot="spinner"><font-awesome-icon icon="spinner" spin size="2x" class="loading"></font-awesome-icon></div>
                    <div slot="no-more">{{ trans('chat.no_more') }}</div>
                    <div slot="no-results">{{ trans('chat.no_results') }}</div>
                </infinite-loading>

                <div v-for="(message, index) in messages" :key="message.id">
                  <div v-if="firstUnreadId && showUnreadTitle && +message.id === +firstUnreadId" class="new-messages">
                    <span>{{ trans('chat.new_messages') }}</span>
                  </div>
                  <div :class="{'outgoing_msg': isMessageSent(message), 'incoming_msg': isMessageReceived(message), 'bot-message-container': isMessageBot(message)}">
                    <roll-dice-message class="bot-message" v-if="isMessageBot(message)" :message="message.message"></roll-dice-message>

                    <div class="incoming_msg_img" v-if="isMessageReceived(message)">
                      <b-avatar :badge="messageAuthorOnline(message)" variant="light" badge-variant="success" size="2rem" class="small-badge"
                                :src="messageAuthor(message) ? messageAuthor(message).avatar : serverUrl + setting('defaultAvatar')"
                                :alt="messageAuthor(message) ? messageAuthor(message).name : null"
                                @click="messageAuthorClick(message)"
                                v-if="previousMessageHasEqualField(index, ['author_id', 'author_type'])"></b-avatar>
                    </div>

                    <div v-if="!isMessageBot(message)" :class="{'sent_msg': isMessageSent(message), 'received_msg': isMessageReceived(message), 'master-msg': isMasterMessage(message) }">
                        <div :class="{'received_withd_msg': isMessageReceived(message)}">
                            <span
                                    v-if="isMessageReceived(message) && previousMessageHasEqualField(index, ['author_id', 'author_type'])"
                                    class="author"
                                    @click="messageAuthorClick(message)">
                                {{ messageAuthor(message) ? messageAuthor(message).name : null }}
                            </span>
                            <span class="time_date" v-if="previousMessageHasEqualField(index, 'created_at')">{{ message.created_at }}</span>
                            <p v-html="messageForDisplay(message.message, isMasterMessage(message))"></p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="typing">{{ typing() }}</div>
            </vue-perfect-scrollbar>

            <template v-slot:footer>
                <b-card-footer ref="chatFooter">
                    <b-form @submit.prevent="sendMessage()" v-if="currentCharacter && currentCharacter.confirmed">
                        <b-input-group>
                            <b-form-textarea
                                    ref="chatTextarea"
                                    :placeholder="trans('chat.placeholder')"
                                    size="sm"
                                    rows="2"
                                    max-rows="3"
                                    name="message"
                                    v-model="newMessage"
                                    no-resize
                                    no-auto-shrink
                                    @input="whisper"
                                    @keydown.enter.exact="submitEnter"
                                    @keydown.ctrl.enter.exact="submitCtrlEnter"
                            ></b-form-textarea>
                            <b-input-group-append>
                                <div class="d-flex flex-column">
                                    <b-button variant="primary" type="submit" class="send-button float-right" :disabled="!newMessage"><font-awesome-icon icon="paper-plane" fixed-width></font-awesome-icon></b-button>
                                    <b-button variant="primary" v-b-modal="rollModalId" class="send-button float-right" :disabled="!!newMessage" v-if="isMaster"><font-awesome-icon :icon="['far', 'dice']" fixed-width></font-awesome-icon></b-button>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form>
                    <div v-else class="not-confirmed">{{ trans('chat.not_confirmed') }}</div>
                </b-card-footer>
            </template>
        </b-card>
        <roll-dice-window
                :modal-id="rollModalId"
                v-if="isMaster"
                :channel="channel"
        ></roll-dice-window>
    </draggable-panel>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import DraggablePanel from "./includes/DraggablePanel";
    import InfiniteLoading from 'vue-infinite-loading';
    import RollDiceWindow from "./includes/Chat/RollDiceWindow";
    import RollDiceMessage from "./includes/Chat/RollDiceMessage";
    import {chatMixin} from "../mixins/chatMixin";

    export default {
        name: "ChatWindow",
        mixins: [
            chatMixin
        ],
        components: {
            RollDiceMessage,
            RollDiceWindow,
            FontAwesomeIcon,
            VuePerfectScrollbar,
            DraggablePanel,
            InfiniteLoading
        },
        data: function () {
            return {
                newMessage: '',//{ id: '', author_id: '', author_type: '', message: '', channel_key: '', channel_type: '', game_key: ''},
                needLoadMore: true, // Если при последней загрузке сообщений не было новых - то больше не грузим // Включаем после инициализации dom
                infiniteLoadingState: null,
                sendCtrlEnter: true,
                showUnreadTitle: true
            }
        },
        props: {
            channel: { required: true }
        },
        created() {
            // Обновляем каждые 2 секунды, чтобы перечитывалось, кто печатает, из стора
            setInterval(() => {
                this.$forceUpdate()
            }, 1000);

            // Канал известен, поэтому прямо тут пытаемся восстановить
            // На всяк, а то на мобилах вылезало
            if (!this.edit && this.autoSaveKey && localStorage.getItem(this.autoSaveKey) && localStorage.getItem(this.autoSaveKey) !== 'null') {
                this.newMessage = localStorage.getItem(this.autoSaveKey).toString();
            }
        },
        computed: {
            user () {
                return this.$store.getters['auth/getUser'];
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            messages () {
                return this.$store.getters['chat/messages'](this.channel) ? this.$store.getters['chat/messages'](this.channel) : [];
            },

            rollModalId() {
                return 'rollWindowModal-' + this.channel.type + '-' + this.channel.id;
            },

            autoSaveKey () {
                return 'chat.rpg.' + this.currentCharacter.key + '.' + this.channel.type + '.' + this.channel.id;
            },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },

            isMobile() {
              return this.$store.getters['auth/isMobile'];
            },

            sendEnter() {
              var sendSetting = this.$store.getters['auth/setting'](this.isMobile ? 'sendSettingMobile' : 'sendSetting');
              if (!sendSetting) {
                sendSetting = this.isMobile ? 'ctrlEnter' : 'enter';
              }
              return sendSetting !== 'ctrlEnter';
            },

            usersOnline () {
              return this.$store.getters['chat/usersOnline'](this.channel) ? this.$store.getters['chat/usersOnline'](this.channel) : [];
            },

            firstUnreadId() {
              var id = 0;
              if (this.messages && this.messages.length) {
                this.messages.forEach(message => {
                  if (!id && message.unread) {
                    id = message.id;
                  }
                });
              }
              return id;
            }
        },
        watch: {
            messages(oldMessages, newMessages) {
                // Обновляем сразу - тут-то уже закончена печать
                this.$forceUpdate();
            },
            newMessage () {
                if (!this.edit && this.autoSaveKey) {
                    localStorage.setItem(this.autoSaveKey, this.newMessage);
                }
            }
        },
        methods: {
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            trans (key) {
                return this.$t(key);
            },

            // fetchAuthors: function () {
            //     this.$store.dispatch('chat/fetchAuthors');
            // },

            // getMessage: function (id) {
            //     this.axios.get('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id + '/' + id).then((response) => {
            //         this.newMessage.id = response.data.id;
            //         this.newMessage.author_id = response.data.author_id;
            //         this.newMessage.message = response.data.message;
            //     }).catch((error) => {
            //         let message = error.response.data.error ? error.response.data.error : error.response.data;
            //         this.$store.dispatch('loading/error', message);
            //         this.$store.dispatch('loading/stop', null);
            //     });
            // },

            /**
             * Enter в поле отправки
             */
            submitEnter: function (event) {
              if (this.sendEnter) {
                event.preventDefault();
                this.edit ? this.updateSelectedMessage() : this.sendMessage();
              }
            },

            /**
             * Ctrl (cmd) + enter в поле отправки
             */
            submitCtrlEnter: function (event) {
              if (!this.sendEnter) {
                event.preventDefault();
                this.edit ? this.updateSelectedMessage() : this.sendMessage();
              }
            },

            sendMessage: function () {
                if (this.showUnreadTitle) {
                  this.showUnreadTitle = false;
                }
                let msg = this.newMessage;
                this.emptyNewMessage();
                this.axios.post('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id, {
                    message: msg
                }).then( (response) => {
                  //this.emptyNewMessage();
                  this.$store.dispatch('chat/fetchNewMessagesNow');
                }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                    this.newMessage = msg;
                });
            },
            // updateMessage: function (id) {
            //     this.axios.patch('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id + '/' + id, {
            //         'message': this.newMessage.message
            //     }).then((response) => {
            //         this.emptyNewMessage();
            //         this.edit = false
            //     }).catch((error) => {
            //         let message = error.response.data.error ? error.response.data.error : error.response.data;
            //         this.$store.dispatch('loading/error', message);
            //         this.$store.dispatch('loading/stop', null);
            //     });
            // },
            // deleteMessage: function (message) {
            //     if (this.isMessageSent(message)) {
            //         this.axios.delete('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id + '/' + message.id
            //         ).then((response) => {
            //             this.emptyNewMessage();
            //         }).catch((error) => {
            //             let message = error.response.data.error ? error.response.data.error : error.response.data;
            //             this.$store.dispatch('loading/error', message);
            //             this.$store.dispatch('loading/stop', null);
            //         });
            //     }
            // },
            // editMessage: function (message) {
            //     // todo - тут по-идее и не надо
            //     return false;
            //     if (this.isMessageSent(message)) {
            //         this.edit = true;
            //         this.getMessage(message.id);
            //     }
            // },
            isMessageSent: function (message) {
                return this.$store.getters['chat/authorIsCurrentChar'](message.author_id, message.author_type) && !(this.isMessageBot(message));
            },
            isMessageReceived: function (message) {
                return !(this.isMessageSent(message)) && !(this.isMessageBot(message));
            },
            isMessageBot: function (message) {
                return message.author_type === 'bot';
            },
            emptyNewMessage: function () {
                // this.newMessage.id = '';
                // this.newMessage.author_id = '';
                // this.newMessage.message = '';
                this.newMessage = '';
            },

            /**
             * Проверка совпадения информации у прошлого сообщения для необходимости ее отображения
             * @param index
             * @param fields
             */
            previousMessageHasEqualField: function (index, fields) {
                var rule = index > 0 && this.isMessageSent(this.messages[index-1]) === this.isMessageSent(this.messages[index]);
                if (rule) {
                    if (!(fields instanceof Array)) {
                        fields = [fields];
                    }
                    fields.forEach(function (field) {
                        rule = rule && this.messages[index-1][field] === this.messages[index][field];
                    }, this);
                }
                return !(rule);
            },

            /**
             * Закрывает основную панель чата
             */
            closePanel() {
                this.$store.dispatch('chat/closeChat', this.channel);
                this.edit = false;
            },

            /**
             * Не перемещать, если не заголовок.
             * @param ev
             * @returns {boolean}
             */
            onDragStartCallback: function (ev) {
                return !!(ev.target.tagName === 'HEADER'
                    || (
                        ev.target.classList.length
                        && (
                            ev.target.classList.contains('card-header')
                            || ev.target.classList.contains('card-header-title')
                        )
                    ));
            },

            /**
             * Автор
             * @param id
             * @param type
             * @returns {*}
             */
            author (id, type) {
                return this.$store.getters['chat/author'](id, type);
            },

            /**
             * Автор сообщения
             * @param message
             * @returns {*}
             */
            messageAuthor (message) {
                return this.author(message.author_id, message.author_type);
            },

            /**
             * При клике на автора - добавить в поле обращение
             * @param message
             */
            messageAuthorClick(message) {
                let author = this.messageAuthor(message);
                if (author) {
                    this.newMessage = author.name + ', ' + this.newMessage;
                    this.$refs.chatTextarea.$el.focus();
                }
            },

            /**
             * Загрузить ранние сообщения.
             */
            loadMore($state) {
                if (this.needLoadMore) {
                    this.infiniteLoadingState = $state;
                    //console.log(evt);
                    // Чтобы не срабатывало во время загрузки
                    this.needLoadMore = false;
                    this.fetchMessages();
                }
            },

            /**
             * Коллбэк.
             */
            loadMoreCallback(needLoadMore = true) {
                this.needLoadMore = needLoadMore;
                //this.needLoadMore = false;
                this.loadTimes++;
                if (this.loadTimes > 7) {
                    this.needLoadMore = false;
                }

                if (this.infiniteLoadingState) {
                    if (needLoadMore) {
                        this.infiniteLoadingState.loaded()
                    } else {
                        this.infiniteLoadingState.complete();
                    }
                }
            },

            /**
             * Специальный запрос старых сообщений.
             */
            fetchMessages: function () {
                this.$store.dispatch('chat/fetchMessages', { channel: this.channel, id: this.messages.length ? this.messages[0].id : null, callback: this.loadMoreCallback });
            },

            whisper: function () {
                this.$store.dispatch('chat/whisper', this.channel);
            },

            /**
             * Кто печатает - мало того что не компьютед, так ещё и вызываем обновление каждые 2 секунды
             * @returns {string}
             */
            typing () {
                let typing = '',
                    names = this.$store.getters['chat/typing'](this.channel);
                if (names.length) {
                    if (names.length === 1) {
                        typing = names[0] + ' ' + this.trans('chat.typing');
                    } else {
                        names.forEach((name, index) => {
                            if (typing) {
                                if (index + 1 < names.length) {
                                    typing += ', ' + name;
                                } else {
                                    typing += ' ' + this.trans('chat.and') + ' ' + name;
                                }
                            } else {
                                typing = name;
                            }
                        });
                        typing += ' ' + this.trans('chat.typings');
                    }
                }

                return typing;
            },

            messageAuthorOnline(message) {
              return this.usersOnline && this.usersOnline.length && !!this.usersOnline.find(user => (message.author_id.toString() === user.id.toString() || message.author_type === 'master') && message.author_type === user.type);
            }
        },
    }
</script>


<style scoped>

</style>
