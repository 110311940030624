<template>
    <div>
        <div class="text-center" v-if="isLoading">
            <b-spinner></b-spinner>
            <p>Загрузка карты...</p>
        </div>
        <div id="map" ref="map" class="map" v-else></div>
        <router-link :to="locationRoute" id="locationPopover">
            <div></div>
        </router-link>
        <router-link :to="locationRoute" id="popoverPosition">
            <div></div>
        </router-link>
        <b-popover target="popoverPosition" placement="auto" custom-class="red-popover" delay="0" :show.sync="showPopover" v-if="popoverContent">
            <div class="media">
                <router-link :to="locationRoute" v-if="popoverContent.image">
                    <img :src="popoverContent.image" class="mr-3 thumbnail" :alt="popoverContent.title" :style="{ height: popoverContent.image_height + 'px', width: popoverContent.image_width + 'px' }">
                </router-link>
                <div class="media-body">
                    <router-link :to="locationRoute"><h5 class="mt-0">{{ popoverContent.title }}</h5></router-link>
                    {{ popoverContent.desc }}
                </div>
            </div>
            <b-avatar-group rounded="circle" size="22px" class="mt-2" v-if="popoverCharacters">
                <b-avatar :badge="isOnline(char)" variant="secondary" badge-variant="success" :src="char.img" :alt="char.name" v-for="char in popoverCharacters" :key="char.id" v-b-tooltip.hover.top="{ title: char.name, variant: 'secondary',  boundary: 'window' }" class="channel-image small-badge" :to="char.type === 'profile' && currentGame.profile_show ? { name: 'profiles.show', params: { id: char.id, seo: char.seo } } : null" :href="char.type === 'user' && char.url ? char.url : null"></b-avatar>
            </b-avatar-group>
        </b-popover>
    </div>
</template>

<script>
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import {getCenter} from 'ol/extent';

import 'ol/ol.css';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import VectorSource from 'ol/source/Vector';
import TileImage from 'ol/source/TileImage';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import TileGrid from 'ol/tilegrid/TileGrid';

import GeoJSON from 'ol/format/GeoJSON';
import {Draw, Modify, Select, Snap} from 'ol/interaction';
import {useGeographic} from 'ol/proj';
import Overlay from 'ol/Overlay';

// Ext
//import Popup from 'openlayers-ext/lib/overlay/Popup';

export default {
    name: "MapShow",
    props: {
        mapKey: {required: true}
    },
    data () {
        return {
            mapInfo: {},
            map: null,
            options: {},
            isLoading: false,
            currentId: null,
            action: 'modify',
            actionOptions: [
                { text: 'Изменить', value: 'modify' },
                { text: 'Добавить', value: 'draw' },
            ],
            locations: [],
            features: [],
            // Layer
            addLocation: null,
            redDataLayer: null,
            selectedFeature: null,
            // for draw
            source: null,
            modify: null,
            snap: null,
            draw: null,
            select: null,
            typeSelect: 'Polygon',
            format: new GeoJSON(),
            highlightStyle: null,
            showPopover: false,
            popoverTargetId: null,
        }
    },

    mounted() {
        this.loadMap();
    },

    methods: {
        loadMap() {
            this.$store.dispatch('loading/start', 'Загрузка карты...', { root: true });
            this.isLoading = true;

            this.axios.get('/api/rpg/map/' + this.currentGame.key + (this.mapKey ? '/' + this.mapKey : ''))
                .then((response) => {
                    //console.log(response.data);
                    this.mapInfo = response.data.info;
                    this.options = response.data.options;
                    this.features = response.data.features;
                    setTimeout(this.makeMap, 300);

                    this.$store.dispatch('loading/stop', null, { root: true });

                    this.$emit('loadMapInfo', this.mapInfo);
                    if (this.mapInfo.bgimage) {
                        this.$store.dispatch('rpg/setBackground', this.mapInfo.bgimage);
                    }
                }).catch((error) => {
                    let message = error && error.response ? (error.response.data.error ? error.response.data.error : error.response.data) : error;
                    if (!(message instanceof Object) || message.message !== 'Карта не найдена.') { // Такое сообщение даже не выводим, просто редирект. Другие выведем, мало ли что.
                        this.$store.dispatch('loading/error', message);
                    }
                    this.$store.dispatch('loading/stop', null);
                    this.$emit('nomap');
                }).then(() => {
                    this.isLoading = false;
                });
        },

        makeMap() {
            this.source = new VectorSource({
                features: this.format.readFeatures(this.geojsonObject),
            });
            this.redDataLayer = new VectorLayer({
                name: 'redDataLayer',
                source: this.source,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(61, 43, 31, 0.0)',
                    }),
                    stroke: new Stroke({
                        color: 'rgba(61, 43, 31, 0.0)',//'#ffcc33',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: 'rgba(61, 43, 31, 0.0)',//'#ffcc33',
                        }),
                    }),
                }),
            });

            this.map = new Map({
                layers: [
                    new TileLayer({
                        title: 'Overlay',
                        // opacity: 0.7,
                        source: new TileImage({
                            attributions: '',
                            tileGrid: new TileGrid({
                                extent: this.options.extent, //[0,-3296,4782,0],
                                origin: this.options.origin, // [0,-3296],
                                resolutions: this.options.resolutions ? this.options.resolutions : [32,16,8,4,2,1],
                                tileSize: this.options.tileSize ? this.options.tileSize : [256, 256]
                            }),
                            tileUrlFunction: (tileCoord) => {
                                var tileCoords = { z: tileCoord[0], x: tileCoord[1], y: tileCoord[2] };
                                Object.keys(tileCoords).forEach(coord => {
                                    if (this.options.tileCoord && this.options.tileCoord[coord]) {
                                        tileCoords[coord] = (this.options.tileCoord[coord].add ? this.options.tileCoord[coord].add : 0) + (this.options.tileCoord[coord].mult ? this.options.tileCoord[coord].mult : 1) * tileCoords[coord]
                                    }
                                })
                                return (process.env.VUE_APP_SERVER_URL + this.options.url
                                    .replace('{z}', String(tileCoords.z))
                                    .replace('{x}', String(tileCoords.x))
                                    .replace('{y}', String(tileCoords.y)));
                            },
                        })
                    }),
                    this.redDataLayer
                ],
                target: 'map',
                view: new View({
                    extent: this.options.extent,
                    minZoom: this.options.minZoom,
                    //projection: 'EPSG:3857',
                    zoom: this.options.zoom,
                    maxZoom: this.options.maxZoom,
                    center: this.options.center ? this.options.center : getCenter(this.options.extent),
                    resolution: this.options.resolution ? this.options.resolution : 16.000000,
                }),
            });

            const highlightStyle = new Style({
                fill: new Fill({
                    color: 'rgba(239, 229, 202, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#654321',
                    width: 3,
                }),
            });
            this.highlightStyle = highlightStyle;

            this.select = new Select({
                // make sure only the desired layer can be selected
                layers: function(vectorLayer) {
                    return vectorLayer.get('name') === 'redDataLayer';
                },
                style: function (feature) {
                    return highlightStyle;
                }
            });

            this.snap = new Snap({
                source: this.source,
            });

            this.select.getFeatures().on('add', this.onSelectFeature);
            this.select.getFeatures().on('change', this.onChangeSelectFeature);
            this.select.getFeatures().on('remove', this.onUnselectFeature);

            this.map.addInteraction(this.select);
            this.map.addInteraction(this.snap);

            // Элемент, для которого будет появлять поповер - меняется только при смене фичи. Также ссылка для ситуации, когда только навели и не двигаются.
            const popupPopover = new Overlay({
                element: document.getElementById('popoverPosition'),
                positioning: 'center-center'
            });
            this.map.addOverlay(popupPopover);

            // Элемент-ссылка "под курсором" мыши для ситуации, когда двигается по выделенной зоне
            const popup = new Overlay({
                element: document.getElementById('locationPopover'),
                positioning: 'center-center'
            });
            this.map.addOverlay(popup);

            this.map.on('pointermove', e => {
                var oldSelectedFeature = this.selectedFeature;

                if (this.selectedFeature !== null) {
                    this.selectedFeature.setStyle(undefined);
                    this.selectedFeature = null;
                }

                this.map.forEachFeatureAtPixel(e.pixel, f => {
                    this.selectedFeature = f;
                    f.setStyle(highlightStyle);
                    return true;
                });

                const element = popup.getElement();
                const coordinate = e.coordinate;

                if (!this.selectedFeature) {
                    this.showPopover = false;
                    this.popoverTargetId = null;
                } else {
                    if (this.currentFeature.id !== this.popoverTargetId) {
                        popupPopover.setPosition(coordinate);
                        this.popoverTargetId = this.currentFeature.id;
                    }

                    this.showPopover = true;
                    popup.setPosition(coordinate);
                }
            });
        },

        showError(error) {
            if (error instanceof Object) {
                error = error.response.data.error
                    ? error.response.data.error
                    : (error.response.data.message ? error.response.data.message : error.response.data)
            }
            this.$bvToast.toast(error, {
                variant: 'danger',
                title: 'Ошибка',
                appendToast: true,
                toaster: 'b-toaster-top-right'
            });
        },

        onSelectFeature(event) {
            //console.log(event);
            this.selectedFeature = event.element;
            this.showPopover = true;
            this.selectedFeature.setStyle(this.highlightStyle);
        },

        onUnselectFeature(event) {
            //console.log(event);
            this.selectedFeature = null;
            this.showPopover = false;
            this.popoverTargetId = null;
        },

        onChangeSelectFeature(event) {
            //console.log(event);
            this.selectedFeature = event.element;
            this.popoverTargetId = null;
            // this.showPopover = false;
            // this.showPopover = true;
        },

        isOnline (char) {
            return this.$store.getters['rpg/isOnline'](char.id, char.type);
        },
    },
    computed: {
        currentFeature() {
            return this.selectedFeature ? this.features.find(feature => feature.id === this.selectedFeature.getId()) : null;
        },

        currentZoom() {
            return this.map ? this.map.getView().getZoom() : null;
        },

        geojsonObject () {
            return {
                'type': 'FeatureCollection',
                'crs': {
                    'type': 'name',
                    'properties': {
                        'name': 'EPSG:3857',
                    },
                },
                'features': this.features.map(mapFeature => mapFeature.feature)
            };
        },

        popoverContent() {
            if (!this.currentFeature || (!this.currentFeature.location && !this.currentFeature.map)) {
                return null;
            }
            return this.currentFeature.location ? this.currentFeature.location : this.currentFeature.map;
        },

        locationRoute() {
            return this.currentFeature && (this.currentFeature.location || this.currentFeature.map)
                ? (
                    this.currentFeature.location
                        ? { name: 'location', params: { currentLocationKey: this.currentFeature.location.key } }
                        : { name: 'map', params: { currentMapKey: this.currentFeature.map.key } }
                )
                : {};
        },

        currentGame () {
            return this.$store.getters['rpg/currentGame'];
        },

        currentCharacter () {
            return this.$store.getters['rpg/currentCharacter'];
        },

        isMaster () {
            return this.$store.getters['rpg/isMaster'];
        },

        popoverCharacters() {
            return this.popoverContent && this.popoverContent.characters ? this.popoverContent.characters : [];
        }
    },
}
</script>

<style lang="scss">
.map {
    width: 100%;
    height: 50vh;

    @media (min-width: 768px) {
        min-height: 500px;
    }
}

#locationPopover div, #popoverPosition div {
    height: 1rem;
    width: 1rem;
}

.ol-control button, .ol-control button {
    background-color: rgba(101, 67, 33, 0.5);;
}

.ol-control button:hover, .ol-control button:focus {
    text-decoration: none;
    background-color: rgba(101, 67, 33, 0.7);;
}

.red-popover {
    left: 0 !important;
}
</style>
