<template>
    <div>
        <b-form @submit.prevent="onSubmit">
            <b-form-group id="name-group" :label="$t('form.name')" label-for="name" :invalid-feedback="errorField('name', profile.name)" :state="stateField('name', profile.name)">
                <b-form-input id="name" v-model="profile.name" type="text" required :placeholder="$t('form.name')" :maxlength="191" :state="stateField('name', profile.name)"></b-form-input>
            </b-form-group>

            <b-row>
<!--                <b-col>-->
<!--                    <avatar-upload-->
<!--                        :old-image="profile.image"-->
<!--                        :old-value="profile.imageValue"-->
<!--                    ></avatar-upload>-->
<!--                </b-col>-->
                <b-col>
                    <b-form-group id="alias-group" :label="$t('form.alias')" label-for="alias" :invalid-feedback="errorField('alias', profile.alias)" :state="stateField('alias', profile.alias)">
                        <b-form-input id="alias" v-model="profile.alias" type="text" required :placeholder="$t('form.alias')" :maxlength="191" :state="stateField('alias', profile.alias)"></b-form-input>
                    </b-form-group>
                    <b-form-group id="species-group" :label="$t('form.species')" label-for="species" :invalid-feedback="errorField('species', profile.species)" :state="stateField('species', profile.species)">
                        <red-multiselect id="species" v-model="profile.species" :options="species" field="species" required :placeholder="$t('form.species')" :state="stateField('species', profile.species)" :allow-empty="false"></red-multiselect>
                    </b-form-group>
                    <b-form-group id="faction-group" :label="$t('form.faction')" label-for="faction" :invalid-feedback="errorField('faction', profile.faction)" :state="stateField('faction', profile.faction)">
                        <red-multiselect id="faction" v-model="profile.faction" :options="factions" field="faction" required :placeholder="$t('form.faction')" :state="stateField('faction', profile.faction)" :allow-empty="false"></red-multiselect>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group id="age-group" :label="$t('form.age')" label-for="age" :invalid-feedback="errorField('age', profile.age)" :state="stateField('age', profile.age)">
                        <b-form-spinbutton id="age" v-model="profile.age" :min="0" :max="255" :step="1" number required :placeholder="$t('form.age')" :state="stateField('age', profile.age)"></b-form-spinbutton>
                    </b-form-group>
                    <b-form-group id="gender-group" :label="$t('form.gender')" label-for="gender" :invalid-feedback="errorField('gender', profile.gender)" :state="stateField('gender', profile.gender)">
                        <red-multiselect id="gender" v-model="profile.gender" :options="genders" field="gender" required :placeholder="$t('form.gender')" :state="stateField('gender', profile.gender)" :allow-empty="false"></red-multiselect>
                    </b-form-group>
                    <b-form-group id="position-group" :label="$t('form.position')" label-for="position" :invalid-feedback="errorField('position', profile.position, false)" :state="stateField('position', profile.position, false)">
                        <red-multiselect id="position" v-model="profile.position" :options="positions" field="position" :placeholder="$t('form.position')" :state="stateField('position', profile.position, false)"></red-multiselect>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-form-group id="activity-group" :label="$t('form.activity')" label-for="activity" :invalid-feedback="errorField('activity', profile.activity)" :state="stateField('activity', profile.activity)">
                <b-form-textarea id="activity" v-model="profile.activity" required :placeholder="$t('form.activity')" :maxlength="4000" :rows="3" :state="stateField('activity', profile.activity)"></b-form-textarea>
            </b-form-group>

            <b-form-group id="look-group" :label="$t('form.look')" label-for="look" :invalid-feedback="errorField('look', profile.look)" :state="stateField('look', profile.look)">
                <b-form-textarea id="look" v-model="profile.look" required :placeholder="$t('form.look')" :maxlength="4000" :rows="3" :state="stateField('look', profile.look)"></b-form-textarea>
            </b-form-group>

            <b-form-group id="biography-group" :label="$t('form.biography')" label-for="biography" :invalid-feedback="errorField('biography', profile.biography)" :state="stateField('biography', profile.biography)">
                <b-form-textarea id="biography" v-model="profile.biography" required :placeholder="$t('form.biography')" :maxlength="4000" :rows="3" :state="stateField('biography', profile.biography)"></b-form-textarea>
            </b-form-group>

            <b-form-group id="temper-group" :label="$t('form.temper')" label-for="temper" :invalid-feedback="errorField('temper', profile.temper)" :state="stateField('temper', profile.temper)">
                <b-form-textarea id="temper" v-model="profile.temper" required :placeholder="$t('form.temper')" :maxlength="4000" :rows="3" :state="stateField('temper', profile.temper)"></b-form-textarea>
            </b-form-group>

            <b-form-group id="skills-group" :label="$t('form.skills')" label-for="skills" :invalid-feedback="errorField('skills', profile.skills)" :state="stateField('skills', profile.skills)">
                <b-form-textarea id="skills" v-model="profile.skills" required :placeholder="$t('form.skills')" :maxlength="4000" :rows="3" :state="stateField('skills', profile.skills)"></b-form-textarea>
            </b-form-group>

            <b-form-group id="bag-group" :label="$t('form.bag')" label-for="bag" :invalid-feedback="errorField('bag', profile.bag)" :state="stateField('bag', profile.bag)">
                <b-form-textarea id="bag" v-model="profile.bag" required :placeholder="$t('form.bag')" :maxlength="4000" :rows="3" :state="stateField('bag', profile.bag)"></b-form-textarea>
            </b-form-group>

            <profile-comments :comments="comments"></profile-comments>

            <b-form-group id="comment" :label="$t('form.comment')" label-for="comment" :invalid-feedback="errorField('newcomment', profile.newcomment, false)" :state="stateField('newcomment', profile.newcomment, false)">
                <comment-editor v-model="profile.newcomment" name="comment" upload-url="" :state="stateField('newcomment', profile.newcomment, false)"></comment-editor>
            </b-form-group>

            <b-button type="submit" variant="primary">{{ buttonTitle }}</b-button>
        </b-form>
    </div>
</template>

<script>
    import RedMultiselect from "./includes/RedMultiselect";
    import AvatarUpload from "./includes/AvatarUpload";
    import ProfileComments from "./ProfileComments";
    import CommentEditor from "./CommentEditor";
    export default {
        name: "ProfileForm",
        components: {CommentEditor, ProfileComments, AvatarUpload, RedMultiselect },
        props: {
            value: { required: true },
            species: { default: () => [] },
            factions: { default: () => [] },
            genders: { default: () => [] },
            positions: { default: () => [] },
            comments: { default: () => [] },
            submit: { default: (profile) => {} },
            buttonTitle: { default: 'Save' },
            errors: { default: null }
        },
        data() {
            return {
                profile: this.value,
                showFormErrors: false // Начиная с первой попытки отправить
            }
        },
        methods: {
            onSubmit() {
                this.showFormErrors = true;
                this.submit(this.profileForSave);
            },

            errorField(field, value, required) {
                if (!value && required) {
                    return this.$t('validation.required');
                }

                if (this.errors && this.errors[field]) {
                    if (Array.isArray(this.errors[field])) {
                        return this.errors[field][0];
                    } else {
                        return this.errors[field];
                    }
                }

                return '';
            },

            stateField(field, value, required = true) {
                return !this.errorField(field, value, required) || !this.showFormErrors ? null : false;
            }
        },
        computed: {
            profileForSave() {
                let profile = Object.assign({}, this.profile);
                Object.keys(profile).forEach((key) => {
                    if (profile[key] instanceof Object && profile[key].value) {
                        profile[key] = profile[key].value;
                    }
                });
                return profile;
            }
        }
    }
</script>

<style scoped>

</style>
