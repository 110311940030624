<template>
    <div>
        <b-card v-for="quest in quests" :key="quest.key" class="bg-rpg-panel mb-3" :class="{'quest-ended': !quest.active}">
            <b-media class="d-block d-md-flex">
                <template v-slot:aside>
                    <b-img :src="quest.imageSmall" blank-color="#654321" width="64" :alt="quest.title" class="mb-3"></b-img>
                </template>

                <h5 class="mt-0">{{ quest.title }}</h5>
                <p v-html="quest.desc"/>

                <b-card v-for="event in quest.events" :key="event.key" class="mb-3" :class="{'quest-ended': !event.active}">
                    <b-media class="d-block d-md-flex">
                        <template v-slot:aside>
                            <b-img :src="event.imageSmall" blank-color="#654321" width="64" :alt="event.title" class="mb-3"></b-img>
                        </template>

                        <b-button variant="primary" v-b-toggle="'read-chronicle-' + quest.key + '-' + event.key" class="float-right ml-2" v-if="event.desc" :title="trans('quest.chronicle')">
                          <span class="when-open"><font-awesome-icon :icon="['fas', 'times']" fixed-width></font-awesome-icon> <span class="d-none d-md-inline">{{ trans('quest.close_chronicle') }}</span></span>
                          <span class="when-closed"><font-awesome-icon :icon="['far', 'book-open']" fixed-width></font-awesome-icon> <span class="d-none d-md-inline">{{ trans('quest.read_chronicle') }}</span></span>
                        </b-button>
                        <router-link class="btn btn-primary float-right" :to="{ name: 'quest.chronicle', params: {questKey: quest.key, eventKey: event.key} }" role="button" :title="trans('quest.write_chronicle')" v-if="event.edit">
                            <font-awesome-icon :icon="['fas', 'pencil']" fixed-width></font-awesome-icon> <span class="d-none d-md-inline">{{ trans('quest.write_chronicle') }}</span>
                        </router-link>
                        <h5 class="mt-0 mb-3">{{ event.title }}</h5>
                        <b-collapse :id="'read-chronicle-' + quest.key + '-' + event.key" v-if="event.desc">
                          <p class="mb-0" v-html="event.desc"/>
                          <b-button variant="primary" v-b-toggle="'read-chronicle-' + quest.key + '-' + event.key" class="mb-3" :title="trans('quest.chronicle')">
                            <font-awesome-icon :icon="['fas', 'times']" fixed-width></font-awesome-icon> {{ trans('quest.close_chronicle') }}
                          </b-button>
                        </b-collapse>
                        <div v-if="event.active && currentCharacter && channelsIsActive && findChannel('rpg_quest_event', event.id)" class="d-flex justify-content-left">
                            <router-link class="btn btn-primary" :to="{ name: 'channel', params: { channelType: 'rpg_quest_event', channelId: event.id } }"  role="button" :title="trans('game.start')">
                                {{ trans('game.start') }}
                            </router-link>
                        </div>
                    </b-media>
                </b-card>
            </b-media>
        </b-card>
    </div>
</template>

<script>
    import Breadcrumb from "../../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: "QuestListMain",
        components: {
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                quests: []
            }
        },
        computed: {
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            channelsIsActive() {
                return this.$store.getters['chat/channelsIsActive'];
            },
        },
        created() {
            // вдруг сюда перешли с другой страницы, и персонаж уже был и не будет меняться и вызываться в вотч
            this.loadQuests();
        },
        watch: {
            // currentGame(newGame, oldGame) {
            //     this.loadProfiles();
            // },
            // Не сразу будет доступно, если страница только открылась
            currentCharacter(newCharacter, oldCharacter) {
                this.loadQuests();
            }
        },
        methods: {
            findChannel (type, id) {
                return this.$store.getters['chat/findChannel']({ type: type, id: id });
            },

            loadQuests() {
                this.quests = [];
                if (!this.currentCharacter) {
                    return;
                }

                this.$store.dispatch('loading/start', 'Загрузка квестов...', { root: true });

                this.axios.get('/api/rpg/quests/' + this.currentGame.key + '/' + this.currentCharacter.key)
                    .then((response) => {
                        // context.dispatch('initUser', {
                        //     user: response.data
                        // });
                        this.quests = response.data;
                        this.$store.dispatch('loading/stop', null, { root: true });
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                        // context.rootState.router.push('login');
                    });
            },
            trans (key) {
                return this.$t(key);
            },
        }
    }
</script>

<style scoped>
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
</style>
