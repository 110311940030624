export const chatMixin = {
    data: function () {
        return {
            currentImageUrl: ''
        }
    },
    methods: {
        /**
         * Является ли объект сообщения принадлежа
         * @param message
         * @returns {boolean}
         */
        isMasterMessage(message) {
            return message.author_type === 'master';
        },

        /**
         * Для отображения
         * @param text
         * @param isMaster
         * @returns {*}
         */
        messageForDisplay(text, isMaster) {
            if (isMaster) {
                // Изображения
                text = text.replace(/\[img=(https?:\/\/[A-Za-z0-9\-\.\/\_]+\.(?:jpe?g|png|gif|tiff?|bmp))\]/g, '<img class="chat-image" src="$1">');
            }

            return text;
        },

        /**
         * Для редактирования
         * @param text
         * @param isMaster
         * @returns {*}
         */
        messageForEdit(text, isMaster) {
            if (isMaster) {
                // Изображения
                text = text.replace(/\[img=(https?:\/\/[A-Za-z0-9\-\.\/\_]+\.(?:jpe?g|png|gif|tiff?|bmp))\]/g, '$1');
            }

            return text;
        },

        /**
         * Открываем изображение по клику
         */
        openChatImage (url) {
            this.currentImageUrl = url;
            this.$bvModal.show('chat-image-channel');
        }
    }
};
