import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import './styles/app.scss';

Vue.config.productionTip = false;

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

// Vuex
import store from './store'
// Vue Router
import router from './router'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.use(FontAwesomeIcon);
window.library = require('./js/icons');

//Vue.config.productionTip = false;

// Axios
import VueAxios from 'vue-axios'
import axios from 'axios';
Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
axios.defaults.headers.common['Accept'] = 'application/json';

// Скролл чата
import VueChatScroll from 'vue-chat-scroll';
Vue.use(VueChatScroll);

import Multiselect from 'vue-multiselect'
// register globally
Vue.component('multiselect', Multiselect).default;

// Echo & Socket.io
import Echo from "laravel-echo"
window.io = require('socket.io-client');
// Have this in case you stop running your laravel echo server
if (typeof io !== 'undefined') {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.VUE_APP_SERVER_URL + ':' + (process.env.VUE_APP_SOCKET_PORT ? process.env.VUE_APP_SOCKET_PORT : '6001'),
    namespace: 'App.Events.RPG',
    auth: {
      headers: {
        'game': null,
        'character': null
      }
    },
  });
}

//axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

// i18n
// Default
var lang = 'ru';
// if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
//     lang = ((window.navigator.languages && window.navigator.languages.length) ? window.navigator.languages[0] : window.navigator.language) || 'ru';
//     if (lang && lang.length > 2) {
//         lang = lang.substr(0, 2);
//     }
// }
// Ready translated locale messages
const messages = {
  //en: require('./i18n/en.json'),
  ru: require('./i18n/ru.json')
};
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
});
window.i18n = i18n;

new Vue({
  i18n,
  store,
  router,
  beforeMount: function () {
    // Для использования и в сторе
    store.commit('rpg/setI18n', i18n);
    // Пока с сайта - передаем данные для инициализации прямо с сервера.
    // Потом уже будем при необходимости запрашивать при старте.
  },
  render: h => h(App)
}).$mount('#app');
