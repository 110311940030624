<template>
    <div>
        <b-modal id="chat-image-channel"
             body-bg-variant="primary"
             size="xl"
             centered
             hide-header
             hide-footer
             scrollable
        >
            <b-img :src="currentImageUrl" fluid></b-img>
        </b-modal>
        <b-modal id="chat-info-channel"
                 header-bg-variant="primary"
                 header-text-variant="light"
                 :title="trans('chat.info')"
                 centered
                 hide-footer
                 scrollable
        >
            <popup-online :channel="channel" v-if="channel"></popup-online>
            <popup-settings></popup-settings>
        </b-modal>
        <b-card
                no-body
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                class="chat-full mt-2 mb-2"
                v-if="channel"
        >
            <header slot="header" v-if="selected.length">
                <b class="card-header-title">{{ trans('chat.messages') }} {{ selected.length }}</b>
                <button class="btn btn-primary" @click="deselectAllMessages">
                    <font-awesome-icon :icon="['fas', 'times']" class="sidebar-icon"/>
                </button>

                <button class="btn btn-primary float-right" @click="deleteSelectedMessage" v-if="isSingleSelected">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" class="sidebar-icon"/>
                </button>
                <button class="btn btn-primary float-right" @click="editSelectedMessage" v-if="isSingleSelected">
                    <font-awesome-icon :icon="['fas', 'pencil']" class="sidebar-icon"/>
                </button>

            </header>
            <header slot="header" v-else>
                <router-link class="btn btn-primary" :to="{ name: 'chat' }">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" class="sidebar-icon"/>
                </router-link>
                <b class="card-header-title">{{ channel ? channel.title : trans('chat.title') }}</b>

                <button class="btn btn-primary float-right" @click="$bvModal.show('chat-info-channel')">
                    <font-awesome-icon :icon="['far', 'info-circle']" size="lg" class="sidebar-icon"/>
                </button>
            </header>

            <vue-perfect-scrollbar v-chat-scroll="{always: false, smooth: false}" class="card-body msg_history" id="chatScroll" ref="chatScroll">
                <infinite-loading direction="top" @infinite="loadMore" forceUseInfiniteWrapper="#chatScroll">
                    <div slot="spinner"><font-awesome-icon icon="spinner" spin size="2x" class="loading"></font-awesome-icon></div>
                    <div slot="no-more">{{ trans('chat.no_more') }}</div>
                    <div slot="no-results">{{ trans('chat.no_results') }}</div>
                </infinite-loading>

                <div v-for="(message, index) in messages" :key="message.id">
                  <div v-if="firstUnreadId && showUnreadTitle && +message.id === +firstUnreadId" class="new-messages">
                    <span>{{ trans('chat.new_messages') }}</span>
                  </div>
                  <div :class="{ 'outgoing_msg': isMessageSent(message), 'incoming_msg': isMessageReceived(message), 'bot-message-container': isMessageBot(message), 'selectable': isSelectableMessage(message), 'selected': isSelectedMessage(message) }" @click="selectMessage(message, $event)">
                    <roll-dice-message class="bot-message" v-if="isMessageBot(message)" :message="message.message"></roll-dice-message>

                    <div class="incoming_msg_img" v-if="isMessageReceived(message)">
                        <b-avatar :badge="messageAuthorOnline(message)" variant="light" badge-variant="success" size="2rem" class="small-badge"
                                :src="messageAuthor(message) ? messageAuthor(message).avatar : serverUrl + setting('defaultAvatar')"
                                :alt="messageAuthor(message) ? messageAuthor(message).name : null"
                                @click="messageAuthorClick(message)"
                                v-if="previousMessageHasEqualField(index, ['author_id', 'author_type'])"></b-avatar>
                    </div>

                    <div v-if="!isMessageBot(message)" :class="{'sent_msg': isMessageSent(message), 'received_msg': isMessageReceived(message), 'master-msg': isMasterMessage(message) }">
                        <div :class="{'received_withd_msg': isMessageReceived(message)}">
                        <span
                                v-if="isMessageReceived(message) && previousMessageHasEqualField(index, ['author_id', 'author_type'])"
                                class="author"
                                @click="messageAuthorClick(message)">
                            {{ messageAuthor(message) ? messageAuthor(message).name : null }}
                        </span>
                            <span class="time_date" v-if="previousMessageHasEqualField(index, 'created_at')">{{ message.created_at }}</span>
                            <p v-html="messageForDisplay(message.message, isMasterMessage(message))"></p>
                        </div>
                    </div>
                 </div>
                </div>
                <div class="typing">{{ typing() }}</div>
            </vue-perfect-scrollbar>

            <template v-slot:footer>
                <b-card-footer>
                    <div class="d-flex justify-content-center"><span class="small" v-if="edit">{{ trans('chat.editing') }}</span></div>
                    <b-form @submit.prevent="edit ? updateSelectedMessage() : sendMessage()" v-if="currentCharacter && currentCharacter.confirmed">
                        <input v-if="edit" name="_method" type="hidden" value="PUT">
                        <b-input-group>
                            <b-form-textarea
                                    ref="chatTextarea"
                                    :placeholder="trans('chat.placeholder')"
                                    size="sm"
                                    rows="2"
                                    max-rows="3"
                                    name="message"
                                    v-model="newMessage"
                                    no-resize
                                    no-auto-shrink
                                    @input="whisper"
                                    :disabled="disableForm"
                                    @keydown.enter.exact="submitEnter"
                                    @keydown.ctrl.enter.exact="submitCtrlEnter"
                            ></b-form-textarea>
                            <b-input-group-append>
                                <div class="d-flex flex-column">
                                    <b-button variant="primary" type="submit" class="send-button float-right" :disabled="!newMessage"><font-awesome-icon icon="paper-plane" fixed-width></font-awesome-icon></b-button>
                                    <b-button variant="primary" v-b-modal="rollModalId" class="send-button float-right" :disabled="!!newMessage" v-if="isMaster"><font-awesome-icon :icon="['far', 'dice']" fixed-width></font-awesome-icon></b-button>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form>
                    <div v-else class="not-confirmed">{{ trans('chat.not_confirmed') }}</div>
                </b-card-footer>
            </template>
        </b-card>
        <roll-dice-window
                :modal-id="rollModalId"
                v-if="isMaster && channel"
                :channel="channel"
        ></roll-dice-window>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="!channel">
            {{ trans('error.wrong_channel') }}
        </div>
        <channel-side-online :channel="channel" v-if="channel"></channel-side-online>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import DraggablePanel from "../../components/includes/DraggablePanel";
    import InfiniteLoading from 'vue-infinite-loading';
    import RollDiceWindow from "../../components/includes/Chat/RollDiceWindow";
    import RollDiceMessage from "../../components/includes/Chat/RollDiceMessage";
    import ChannelSideOnline from "../../components/includes/Chat/ChannelSideOnline";
    import {chatMixin} from "../../mixins/chatMixin";
    import PopupOnline from "../../components/includes/Chat/PopupOnline";
    import PopupSettings from "@/components/includes/Chat/PopupSettings";

    export default {
        name: "Channel",
        mixins: [
            chatMixin
        ],
        components: {
          PopupSettings,
            PopupOnline,
            ChannelSideOnline,
            RollDiceMessage,
            RollDiceWindow,
            FontAwesomeIcon,
            VuePerfectScrollbar,
            DraggablePanel,
            InfiniteLoading
        },
        data: function () {
            return {
                edit: false,
                selected: [],
                newMessage: '',//{ id: '', author_id: '', author_type: '', message: '', channel_key: '', channel_type: '', game_key: ''},
                messageId: null, // для редактирования
                needLoadMore: true, // Если при последней загрузке сообщений не было новых - то больше не грузим // Включаем после инициализации dom
                infiniteLoadingState: null,
                disableForm: false,
                beforeEditMessage: '',
                showUnreadTitle: true
            }
        },
        created() {
            this.setChannel(this.$route.params.channelType, this.$route.params.channelId);
            // Обновляем каждые 2 секунды, чтобы перечитывалось, кто печатает, из стора
            setInterval(() => {
                this.$forceUpdate()
            }, 1000);
        },
        beforeRouteUpdate (to, from, next) {
            this.setChannel(to.params.channelType, to.params.channelId);
            next();
        },
        beforeRouteLeave (to, from, next) {
            this.unsetChannel();
            next();
        },
        watch: {
            messages(oldMessages, newMessages) {
                // Обновляем сразу - тут-то уже закончена печать
                this.$forceUpdate();
            },
            newMessage () {
                if (!this.edit && this.autoSaveKey) {
                    localStorage.setItem(this.autoSaveKey, this.newMessage);
                }
            },
            channel () {
                // Тут обнуляем при смене канала. Мы сохраняли (я надеюсь) старое сообщение ранее.
                this.newMessage = '';

                // На всяк, а то на мобилах вылезало
                if (!this.edit && this.autoSaveKey && localStorage.getItem(this.autoSaveKey) && localStorage.getItem(this.autoSaveKey) !== 'null') {
                    this.newMessage = localStorage.getItem(this.autoSaveKey).toString();
                }
            },
            currentCharacter () {
                // При установке другого пользователя сбрасываем индикатор того, что больше грузить не надо
                this.needLoadMore = true;
            },
            selected () {
                // Если при изменении селестед было редактирование - убираем его, возвращаем как было
                if (this.edit) {
                    this.newMessage = this.beforeEditMessage;
                    this.edit = false;
                }
            }
        },
        computed: {
            user () {
                return this.$store.getters['auth/getUser'];
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            channel () {
                return this.$store.getters['chat/channel'];
            },
            messages () {
                return this.channel && this.$store.getters['chat/messages'](this.channel) ? this.$store.getters['chat/messages'](this.channel) : [];
            },

            rollModalId() {
                return this.channel ? 'rollWindowModal-' + this.channel.type + '-' + this.channel.id : 'rollSomeChannel';
            },

            autoSaveKey () {
                return this.channel ? 'chat.rpg.' + this.currentCharacter.key + '.' + this.channel.type + '.' + this.channel.id : null;
            },

            /**
             * Выделен ли только один элемент
             * @returns {boolean}
             */
            isSingleSelected() {
                return this.selected.length === 1;
            },

            singleSelected() {
                return this.isSingleSelected ? this.selected[0] : null;
            },

            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },

            isMobile() {
              return this.$store.getters['auth/isMobile'];
            },

            sendEnter() {
              var sendSetting = this.$store.getters['auth/setting'](this.isMobile ? 'sendSettingMobile' : 'sendSetting');
              if (!sendSetting) {
                sendSetting = this.isMobile ? 'ctrlEnter' : 'enter';
              }
              return sendSetting !== 'ctrlEnter';
            },

            usersOnline () {
              return this.$store.getters['chat/usersOnline'](this.channel) ? this.$store.getters['chat/usersOnline'](this.channel) : [];
            },

            firstUnreadId() {
              var id = 0;
              if (this.messages && this.messages.length) {
                this.messages.forEach(message => {
                  if (!id && message.unread) {
                    id = message.id;
                  }
                });
              }
              return id;
            }
        },
        methods: {
            setChannel (channelType, channelId) {
                // При установке канала сбрасываем индикатор того, что больше грузить не надо
                this.needLoadMore = true;

                let channelObject = { id: channelId, type: channelType };
                this.$store.dispatch('chat/setChannel', channelObject);
                // Запрашиваем онлайн - для отметки "Прочитано" в каналах
                this.$store.dispatch('rpg/getOnlineNow');
            },

            unsetChannel () {
                this.$store.dispatch('chat/unsetChannel');
            },

            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            trans (key) {
                return this.$t(key);
            },

            // fetchAuthors: function () {
            //     this.$store.dispatch('chat/fetchAuthors');
            // },

            getMessage: function (id) {
                this.axios.get('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id + '/' + id).then((response) => {
                    //this.newMessage.id = response.data.id;
                    //this.newMessage.author_id = response.data.author_id;
                    this.newMessage = this.messageForEdit(response.data.message, this.isMasterMessage(response.data));
                }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                });
            },

            /**
             * Enter в поле отправки
             */
            submitEnter: function (event) {
              if (this.sendEnter) {
                event.preventDefault();
                this.edit ? this.updateSelectedMessage() : this.sendMessage();
              }
            },

            /**
             * Ctrl (cmd) + enter в поле отправки
             */
            submitCtrlEnter: function (event) {
              if (!this.sendEnter) {
                event.preventDefault();
                this.edit ? this.updateSelectedMessage() : this.sendMessage();
              }
            },

            sendMessage: function () {
                if (this.showUnreadTitle) {
                  this.showUnreadTitle = false;
                }
                let msg = this.newMessage;
                this.disableForm = true;
                //this.emptyNewMessage();
                this.axios.post('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id, {
                    message: msg
                }).then( (response) => {
                    //this.emptyNewMessage();
                    this.newMessage = '';
                    this.disableForm = false;
                    this.$store.dispatch('chat/fetchNewMessagesNow');
                }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                    this.newMessage = msg;
                    this.disableForm = false;
                });
            },
            deleteSelectedMessage() {
                this.$bvModal.msgBoxConfirm(this.trans('chat.delete_confirm'), {
                    okTitle: this.trans('chat.delete_yes'),
                    cancelTitle: this.trans('chat.delete_no'),
                    centered: true
                }).then(value => {
                    if (value) {
                        this.deleteMessage(this.getMessageById(this.singleSelected));
                    }
                })
            },
            deleteMessage: function (message) {
                if (this.isSelectableMessage(message)) {
                    this.axios.delete('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id + '/' + message.id
                    ).then((response) => {
                        //this.emptyNewMessage();
                        this.deselectAllMessages();
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                    });
                }
            },
            editSelectedMessage() {
                this.editMessage(this.getMessageById(this.singleSelected));
            },
            editMessage: function (message) {
                if (this.isSelectableMessage(message)) {
                    this.edit = true;
                    this.beforeEditMessage = this.newMessage;
                    this.getMessage(message.id);
                }
            },
            updateSelectedMessage() {
                this.updateMessage(this.getMessageById(this.singleSelected));
            },
            updateMessage: function (message) {
                if (this.isSelectableMessage(message)) {
                    this.axios.patch('/api/rpg/chat/message/' + this.currentGame.key + '/' + this.currentCharacter.key + '/' + this.channel.type + '/' + this.channel.id + '/' + message.id, {
                        'message': this.newMessage
                    }).then((response) => {
                        //this.emptyNewMessage();
                        // this.newMessage = this.beforeEditMessage;
                        // this.edit = false;
                        // В этом методе теперь проверяется, редактируется или нет
                        this.deselectAllMessages();
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);
                    });
                }
            },

            isMessageSent: function (message) {
                return this.$store.getters['chat/authorIsCurrentChar'](message.author_id, message.author_type) && !(this.isMessageBot(message));
            },
            isMessageReceived: function (message) {
                return !(this.isMessageSent(message)) && !(this.isMessageBot(message));
            },
            isMessageBot: function (message) {
                return message.author_type === 'bot';
            },
            // emptyNewMessage: function () {
            //     // this.newMessage.id = '';
            //     // this.newMessage.author_id = '';
            //     // this.newMessage.message = '';
            //     this.newMessage = '';
            // },

            /**
             * Проверка совпадения информации у прошлого сообщения для необходимости ее отображения
             * @param index
             * @param fields
             */
            previousMessageHasEqualField: function (index, fields) {
                var rule = index > 0 && this.isMessageSent(this.messages[index-1]) === this.isMessageSent(this.messages[index]);
                if (rule) {
                    if (!(fields instanceof Array)) {
                        fields = [fields];
                    }
                    fields.forEach(function (field) {
                        rule = rule && this.messages[index-1][field] === this.messages[index][field];
                    }, this);
                }
                return !(rule);
            },

            /**
             * Автор
             * @param id
             * @param type
             * @returns {*}
             */
            author (id, type) {
                return this.$store.getters['chat/author'](id, type);
            },

            /**
             * Автор сообщения
             * @param message
             * @returns {*}
             */
            messageAuthor (message) {
                return this.author(message.author_id, message.author_type);
            },

            /**
             * При клике на автора - добавить в поле обращение
             * @param message
             */
            messageAuthorClick(message) {
                let author = this.messageAuthor(message);
                if (author) {
                    this.newMessage = author.name + ', ' + this.newMessage;
                    this.$refs.chatTextarea.$el.focus();
                }
            },

            /**
             * Загрузить ранние сообщения.
             */
            loadMore($state) {
                if (this.needLoadMore) {
                    this.infiniteLoadingState = $state;
                    // Чтобы не срабатывало во время загрузки
                    this.needLoadMore = false;
                    this.fetchMessages();
                }
            },

            /**
             * Коллбэк.
             */
            loadMoreCallback(needLoadMore = true) {
                this.needLoadMore = needLoadMore;

                if (this.infiniteLoadingState) {
                    if (needLoadMore) {
                        this.infiniteLoadingState.loaded();
                    } else {
                        this.infiniteLoadingState.complete();
                    }
                }
            },

            /**
             * Специальный запрос старых сообщений.
             */
            fetchMessages: function () {
                this.$store.dispatch('chat/fetchMessages', { channel: this.channel, id: this.messages.length ? this.messages[0].id : null, callback: this.loadMoreCallback });
            },

            whisper: function () {
                this.$store.dispatch('chat/whisper', this.channel);
            },

            /**
             * Кто печатает - мало того что не компьютед, так ещё и вызываем обновление каждые 2 секунды
             * @returns {string}
             */
            typing () {
                let typing = '',
                    names = this.$store.getters['chat/typing'](this.channel);
                if (names.length) {
                    if (names.length === 1) {
                        typing = names[0] + ' ' + this.trans('chat.typing');
                    } else {
                        names.forEach((name, index) => {
                            if (typing) {
                                if (index + 1 < names.length) {
                                    typing += ', ' + name;
                                } else {
                                    typing += ' ' + this.trans('chat.and') + ' ' + name;
                                }
                            } else {
                                typing = name;
                            }
                        });
                        typing += ' ' + this.trans('chat.typings');
                    }
                }

                return typing;
            },

            /**
             * Выделить сообщение или снять с него выделение
             * @param message
             * @param event
             */
            selectMessage (message, event) {
                // Проверяем, если клик на картинку - открываем её
                if(event.target.className === 'chat-image') {
                    return this.openChatImage(event.target.src);
                }

                if (!this.isSelectableMessage(message)) {
                    return;
                }

                if (!this.isSelectedMessage(message)) {
                    this.selected.push(message.id);
                } else {
                    this.selected.splice(this.selected.indexOf(message.id), 1);
                }
            },

            /**
             * Сбросить выделение всех сообщений.
             */
            deselectAllMessages() {
                this.selected = [];
            },

            /**
             * Выделено ли сообщение
             * @param message
             * @returns {boolean}
             */
            isSelectedMessage(message) {
                return this.selected.indexOf(message.id) !== -1;
            },

            /**
             * Можно ли выделить сообщение
             * @param message
             * @returns {boolean}
             */
            isSelectableMessage(message) {
                return !this.isMessageBot(message) && (this.isMessageSent(message) || this.isMaster);
            },

            notifyForMessage() {
                alert('Позже эта кнопка будет действовать как кнопка уведомления о сообщении всего чата.');
            },

            getMessageById(id) {
                return this.messages.find((message) => message.id === id );
            },

            messageAuthorOnline(message) {
                return this.usersOnline && this.usersOnline.length && !!this.usersOnline.find(user => (message.author_id.toString() === user.id.toString() || message.author_type === 'master') && message.author_type === user.type);
            }
        },
    }
</script>


<style scoped>

</style>
