<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon icon="users" size="2x"></font-awesome-icon></span>
            <div class="lh-100 flex-grow-1">
                <h4 class="mb-0 redfont lh-100">{{ trans('profiles.title') }}</h4>
            </div>
            <router-link class="btn btn-outline-rpg-light float-right" :to="{ name: 'profiles.choose' }" role="button" :title="trans('profiles.choose')" v-if="currentGame.profile_choose && isMaster">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon> {{ trans('profiles.choose') }}
            </router-link>
        </div>

        <div class="card-rpg-header my-3 p-3 text-rpg-light bg-rpg-primary rounded box-shadow" v-if="profile">
            <div class="media d-block d-md-flex">
                <img class="d-flex mb-3 mx-auto rounded" :src="profile.avatar" :alt="profile.name">
                <div class="media-body ml-md-3 ml-0">
                    <h4 class="mt-0 text-center text-md-left redfont">{{ profile.name }}</h4>
                    <img class="mr-1 rounded" :src="profile.factionImage" :alt="profile.factionTitle" v-if="profile.factionImage">
                    <img class="mr-1 rounded" :src="profile.speciesImage" :alt="profile.speciesTitle" v-if="profile.speciesImage">
                    <img class="mr-1 rounded" :src="profile.positionImage" :alt="profile.positionTitle" v-if="profile.positionImage">
                </div>
            </div>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="profile">
            <div class="form-group row" v-if="profile.alias">
                <label for="alias" class="col-sm-2 col-form-label">{{ trans('form.alias') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="alias">{{ profile.alias }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.factionColorTitle">
                <label for="faction" class="col-sm-2 col-form-label">{{ trans('form.faction') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="faction" v-html="profile.factionColorTitle"/>
                </div>
            </div>

            <div class="form-group row" v-if="profile.speciesTitle">
                <label for="species" class="col-sm-2 col-form-label">{{ trans('form.species') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="species">{{ profile.speciesTitle }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.positionTitle">
                <label for="position" class="col-sm-2 col-form-label">{{ trans('form.position') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="position">{{ profile.positionTitle }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.genderInfo">
                <label for="bag" class="col-sm-2 col-form-label">{{ trans('form.gender') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="gender"><font-awesome-icon :icon="['fas', profile.genderIcon]" size="lg" v-if="profile.genderIcon"></font-awesome-icon> {{ profile.genderInfo }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.ageInfo && profile.ageInfo !== trans('age.unknown')">
                <label for="age" class="col-sm-2 col-form-label">{{ trans('form.age') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="age">{{ profile.ageInfo }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.activity">
                <label for="activity" class="col-sm-2 col-form-label">{{ trans('form.activity') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="activity">{{ profile.activity }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.look">
                <label for="look" class="col-sm-2 col-form-label">{{ trans('form.look') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="look">{{ profile.look }}</span>
                </div>
            </div>

            <div class="form-group row" v-if="profile.temper">
                <label for="temper" class="col-sm-2 col-form-label">{{ trans('form.temper') }}</label>
                <div class="col-sm-10">
                    <span class="form-control-plaintext" id="temper">{{ profile.temper }}</span>
                </div>
            </div>

            <div v-if="isMaster">
                <div class="form-group row" v-if="profile.biography">
                    <label for="biography" class="col-sm-2 col-form-label">{{ trans('form.biography') }}</label>
                    <div class="col-sm-10">
                        <span class="form-control-plaintext" id="biography">{{ profile.biography }}</span>
                    </div>
                </div>

                <div class="form-group row" v-if="profile.skills">
                    <label for="skills" class="col-sm-2 col-form-label">{{ trans('form.skills') }}</label>
                    <div class="col-sm-10">
                        <span class="form-control-plaintext" id="skills">{{ profile.skills }}</span>
                    </div>
                </div>

                <div class="form-group row" v-if="profile.bag">
                    <label for="bag" class="col-sm-2 col-form-label">{{ trans('form.bag') }}</label>
                    <div class="col-sm-10">
                        <span class="form-control-plaintext" id="bag">{{ profile.bag }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="location" class="col-sm-2 col-form-label">{{ trans('location.current_label') }}</label>
                    <div class="col-sm-10">
                        <span class="form-control-plaintext" id="location">
                            <router-link :to="{ name: 'location', params: { currentLocationKey: profile.location.key } }" v-if="profile.location">
                                {{ profile.location.title }}
                            </router-link>
                            <span v-else>{{ trans('location.unknown') }}</span>

                            <div class="float-right" v-if="isMaster && false">
                                <a class="btn btn-rpg-primary" href="route('rpg.profile.move', [ 'id' => $profile->id])" role="button"><font-awesome-icon icon="map"></font-awesome-icon> {{ trans('master.move') }}</a>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-else>
            {{ trans('profile.no_profiles') }}
        </div>
    </div>
</template>

<script>
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Breadcrumb from "../../components/includes/Breadcrumb";

    export default {
        name: "ProfilesShow",
        components: {
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                profile: null
            }
        },
        computed: {
            breadcrumb() {
                var arr = [
                    { route: { name: 'main' }, title: this.trans('title') }
                ];

                if (this.currentGame.enable_profiles) {
                    arr.push({ route: { name: 'profiles.index' }, title: this.trans('profiles.title') });
                }

                if (this.profile) {
                    arr.push({ title: this.profile.name });
                }
                return arr;
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            }
        },
        created() {
            this.loadProfile(this.$route.params.id);
        },
        watch: {
            // currentGame(newGame, oldGame) {
            //     this.loadProfiles();
            // },
            // Не сразу будет доступно, если страница только открылась
            currentCharacter(newCharacter, oldCharacter) {
                this.loadProfile(this.$route.params.id);
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.loadProfile(to.params.id);
            next();
        },
        methods: {
            hasCharacter (key) {
                return this.$store.getters['rpg/hasCharacter'](key);
            },
            loadProfile(id) {
                this.$store.dispatch('loading/start', 'Загрузка персонажа...', { root: true });

                this.axios.get('/api/rpg/profiles/' + this.currentGame.key + '/' + id)
                    .then((response) => {
                        // context.dispatch('initUser', {
                        //     user: response.data
                        // });
                        this.profile = response.data;
                        this.$store.dispatch('loading/stop', null, { root: true });
                    }).catch((error) => {
                    let message = error.response.data.error ? error.response.data.error : error.response.data;
                    this.$store.dispatch('loading/error', message);
                    this.$store.dispatch('loading/stop', null);
                    // context.rootState.router.push('login');
                });
            },
            trans (key) {
                return this.$t(key);
            },
        }
    }
</script>

<style scoped>

</style>

