<template>
    <b-card
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white"
        class="chat-channels"
        v-if="channelsIsActive"
    >
        <vue-perfect-scrollbar>
            <b-list-group flush>
                <b-list-group-item v-b-tooltip.hover.left="{title: channelItem.title,  boundary: 'window'}" :active="isWindowChannel(channelItem)" :key="index" v-for="(channelItem, index) in channels" @click="chooseChannel(channelItem)" class="list-group-item-action">
                  <b-avatar :badge="channelItem.unreads ? '' + channelItem.unreads : false" variant="light" badge-variant="primary" :src="channelItem.image ? channelItem.image : serverUrl + setting('defaultAvatar')" class="channel-image" :alt="channelItem.title"></b-avatar>
                </b-list-group-item>
            </b-list-group>
        </vue-perfect-scrollbar>
    </b-card>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import DraggablePanel from "./includes/DraggablePanel";

    export default {
        name: "ChatChannels",
        components: {
            FontAwesomeIcon,
            VuePerfectScrollbar,
            DraggablePanel
        },
        data: function () {
            return {
                // edit: false,
                // newMessage: { id: '', author_id: '', author_type: '', message: '', channel_key: '', channel_type: '', game_key: ''},
            }
        },
        computed: {
            user () {
                return this.$store.getters['auth/getUser'];
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            channels () {
                return this.$store.getters['chat/channels'];
            },
            // authors () {
            //     return this.$store.getters['chat/authors'];
            // },
            channel () {
                return this.$store.getters['chat/channel'];
            },
            channelsIsActive() {
                return this.$store.getters['chat/channelsIsActive'];
            },
            // messages () {
            //     return this.$store.getters['chat/messages'];
            // },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },
        },
        // mounted: function () {
        //     this.listen();
        //     this.fetchChannels();
        //     //this.fetchUsers();
        //     //this.scrollToEnd();
        // },

        // Перенесено в стор в момент установки игры и персонажа
        // watch: {
        //     currentGame(newGame, oldGame) {
        //         this.fetchChannels();
        //     },
        //     currentCharacter(newCharacter, oldCharacter) {
        //         this.fetchChannels();
        //     }
        // },
        methods: {
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            trans (key) {
                return this.$t(key);
            },

            // listen() {
            //     Echo.channel('laravel_database_chat.1.messages')
            //         .listen('ChatMessageSent', event => {
            //
            //             console.log(event);
            //
            //             this.messages.push(event.message)
            //             //this.assignMessages()
            //         })
            //         .listen('ChatMessageDeleted', event => {
            //             this.fetchMessages()
            //         })
            //         .listen('ChatMessageUpdated', event => {
            //             this.fetchMessages()
            //         });
            // },
            // scrollToEnd: function() {
            //     // var container = this.$el.container;
            //     this.$refs.container.scrollTop = this.$refs.container.scrollHeight+30;
            // },

            // fetchChannels: function () {
            //     this.$store.dispatch('chat/fetchChannels');
            // },

            chooseChannel: function (channel) {
                //this.channel = channel;
                if (this.isWindowChannel(channel)) {
                    this.$store.dispatch('chat/closeChat', channel);
                } else {
                    this.$store.dispatch('chat/openChat', channel);
                }

                //this.fetchMessages();
                //this.edit = false;
            },

            /**
             * Проверяет, открыто ли окно чата выбранного канала.
             * @param channel
             * @returns {channel|{}|boolean}
             */
            isWindowChannel(channel) {
                return this.$store.getters['chat/isWindowChannel'](channel);
            },

        },
    }
</script>

<style scoped>

</style>
