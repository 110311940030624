import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { redPopover } from './stores/RedPopover';
import { moduleLoading } from './stores/Loading';
import { moduleAuth } from './stores/Auth';
import { moduleSettings } from "./stores/Settings";
import { moduleRpg } from "./stores/Rpg";
import { moduleChat } from "./stores/Chat";
import { moduleMessage } from "./stores/Message";

// https://vuex.vuejs.org/ru/
// Управление основным хранилищем, с подмодулями

const store = new Vuex.Store({
    modules: {
        popover: redPopover,
        loading: moduleLoading,
        auth: moduleAuth,
        settings: moduleSettings,
        rpg: moduleRpg,
        chat: moduleChat,
        message: moduleMessage
    },
    state: {
        //router: {},
    },
    mutations: {
        // setRouter(state, payload) {
        //     state.router = payload;
        // },

    },
    getters: {

    }
});

export default store
