import Vue from 'vue';

export const moduleMessage = {
    namespaced: true,

    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        /**
         * Универсальный вывод сообщения.
         * Если нужно особо выводить - вызывать его.
         * Иначе - конкретного типа, те что ниже.
         * @param context
         * @param messageOptions
         */
        message (context, messageOptions) {
            const vm = new Vue(); // Пока так
            vm.$bvToast.toast(messageOptions.message, Object.assign({
                autoHideDelay: 3000,
                appendToast: true,
                toaster: 'b-toaster-bottom-right'
            }, messageOptions.options));
        },

        success (context, message) {
            context.dispatch('message', { message: message, options: { variant: 'success' } });
        },

        error (context, message) {
            // Если это объект с message, то выводим только message - так приходят основные ошибки ларавела.
            // Для других случаев надо просто передавать сразу строку
            if (message instanceof Object && message.message) {
                message = message.message;
            }

            context.dispatch('message', { message: message, options: { variant: 'danger', title: 'Ошибка' } });
        }
    }
};
