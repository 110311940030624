<template>
    <div>
        <sidebar v-model="sidebarOpened"></sidebar>

        <nav class="navbar navbar-expand-lg fixed-top" :class="{ 'navbar-dark': isMaster || !currentCharacter || currentCharacter.confirmed, 'bg-danger': isMaster, 'bg-dark': !currentCharacter || (!isMaster && currentCharacter.confirmed), 'navbar-light bg-warning': !isMaster && currentCharacter && !currentCharacter.confirmed }" id="navbar" style="z-index: 1000;width: 100%;">
            <div class="container-fluid rednav-container">
                <div class="navbar-header">

                </div><!--navbar-header-->

                <div class="collapse navbar-collapse navbar-expand-md">

                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link sidebar-toggle" data-ripple-centered="true" @click="toogleSidebar">
                                <font-awesome-icon icon="bars" size="lg" />
                            </a>
                        </li>

                        <router-link :to="{ name: 'main' }" class="nav-item navbar-text game-title" tag="li" v-if="currentGame">
                            {{ currentGame.title }}
                        </router-link>
                    </ul>

                    <!-- Right Side Of Navbar -->
                    <ul class="navbar-nav navbar-right">

                        <li class="nav-item hide-mobile" v-if="currentCharacter && !currentCharacter.confirmed">
                            <b-badge variant="primary">{{ trans('profile.not_confirmed') }}</b-badge>
                        </li>

                        <li class="nav-item hide-mobile" v-if="currentCharacter && !isUserCharacter">
                            <router-link class="nav-link" :to="{ name: 'profile.index' }" active-class="active">
                                <font-awesome-icon :icon="['fas', 'user']" class="sidebar-icon" fixed-width></font-awesome-icon>
                                {{ trans('profile.menu') }}
                            </router-link>
                        </li>
                        <li class="nav-item hide-mobile" v-else-if="currentGame && currentGame.profile_create">
                          <router-link class="nav-link" :to="{ name: 'profile.create' }" active-class="active">
                            <font-awesome-icon :icon="['fas', 'plus']" class="sidebar-icon" fixed-width></font-awesome-icon>
                            {{ trans('create.title') }}
                          </router-link>
                        </li>
                        <li class="nav-item hide-mobile" v-if="currentGame && currentGame.enable_profiles">
                            <router-link class="nav-link" :to="{ name: 'profiles.index' }" active-class="active">
                                <font-awesome-icon :icon="['fas', 'users']" class="sidebar-icon" fixed-width></font-awesome-icon>
                                {{ trans('profiles.title') }}
                            </router-link>
                        </li>
                        <li class="nav-item hide-mobile" v-if="currentGame && currentGame.enable_map">
                            <router-link class="nav-link" :to="{ name: 'map' }" active-class="active" :class="{'active': $route.name === 'map' || $route.name === 'location'}">
                                <font-awesome-icon :icon="['fas', 'map-signs']" class="sidebar-icon" fixed-width></font-awesome-icon>
                                {{ trans('map.title') }}
                            </router-link>
                        </li>
                        <li class="nav-item hide-mobile" v-if="channelsIsActive">
                            <router-link class="nav-link" :to="{ name: 'chat' }" active-class="active">
                                <font-awesome-icon :icon="['fas', 'comments']" class="sidebar-icon" fixed-width></font-awesome-icon>
                                {{ trans('chat.title') }}
                                <b-badge variant="secondary" pill v-if="chatUnread">{{ chatUnread }}</b-badge>
                            </router-link>
                        </li>
                        <li class="nav-item hide-mobile" v-if="currentGame && currentGame.rules">
                            <router-link class="nav-link" :to="{ name: 'rules' }" active-class="active">
                                <font-awesome-icon :icon="['far', 'scroll-old']" class="sidebar-icon" fixed-width></font-awesome-icon>
                                {{ trans('game.rules') }}
                            </router-link>
                        </li>

                        <!-- Authentication Links -->

                        <li class="nav-item" v-if="!isAuth">
                            <router-link class="nav-link" :to="{ name: 'login' }" active-class="active">
                                {{ trans('navs.login') }}
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="!isAuth"><a class="nav-link" :href="serverUrl + setting('urlRegister')">{{ trans('navs.register') }}</a></li>

                        <b-dropdown class="nav-item red-nav-dropdown" variant="link" v-if="isAuth && currentCharacter">
                            <template v-slot:button-content>
                                <img :src="currentCharacter.image" :alt="currentCharacter.name" class="img-thumbnail tinyPhoto"> {{ currentCharacter.name }} <span class="caret"></span>
                            </template>

                            <b-dropdown-item @click="setCharacter(key)" v-for="key in charactersKeys" v-if="!isCharacter(key)" :key="key">
                                <img :src="getCharacter(key).image" :alt="getCharacter(key).name" class="img-thumbnail tinyPhoto"> {{ getCharacter(key).name }}
                            </b-dropdown-item>

                            <b-dropdown-divider v-if="charactersKeys.length && charactersKeys.length > 1"></b-dropdown-divider>

                            <b-dropdown-item v-if="!currentCharacter.confirmed || currentGame.profile_edit" :to="{ name: 'profile.edit' }">
                                <font-awesome-icon icon="pencil"></font-awesome-icon> {{ trans('edit.button') }}
                            </b-dropdown-item>

                            <b-dropdown-item href="/logout" @click.prevent="logout">
                                <font-awesome-icon :icon="['fas', 'sign-out']"></font-awesome-icon> {{ trans('navs.logout') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </ul>
                </div><!--navbar-collapse-->
            </div><!--container-->
        </nav>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Sidebar from "./Sidebar";

    export default {
        name: "Navigation",
        data() {
            return {
                sidebarOpened: false
            }
        },
        components: {
            FontAwesomeIcon,
            Sidebar
        },
        computed: {
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            // charactersList () {
            //     return this.$store.getters['rpg/charactersList'];
            // }
            charactersKeys () {
                return this.$store.getters['rpg/charactersKeys'];
            },
            channelsIsActive() {
                return this.$store.getters['chat/channelsIsActive'];
            },
            isUserCharacter () {
                return this.$store.getters['rpg/isUserCharacter'];
            },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },
            chatUnread () {
              return this.$store.getters['chat/unreadCount'];
            }
        },
        methods: {
            trans (key) {
                return this.$t(key);
            },
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },
            isCharacter (key) {
                return this.$store.getters['rpg/isCharacter'](key);
            },
            getCharacter (key) {
                return this.$store.getters['rpg/getCharacter'](key);
            },
            setCharacter (key) {
                this.$store.dispatch('rpg/setCharacter', key);
            },
            toogleSidebar () {
                this.sidebarOpened = !this.sidebarOpened;
            },
            logout () {
                this.$store.dispatch('auth/logout');
            }
        }
    }
</script>

<style scoped>

</style>
