<template>
    <div>
        <make-turn
                v-if="currentCharacter"
                save-suffix="turn"
                show-current-location
                @sent="fetchNewTurnsNow"
        ></make-turn>

        <game-group-switch class="mt-2"></game-group-switch>

        <transition-group name="list" tag="div">
            <turn v-for="(turn, index) in turns" :turn="turn" :key="turn.id" @deleted="deleteTurn" @edited="editTurn" class="list-item" :show-location="showLocation" :previous-turn="showLocation && index > 0 ? turns[index-1] : null" :next-turn="showLocation && index < turns.length ? turns[index+1] : null" order="desc"></turn>
        </transition-group>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="!turns.length">
            {{ trans('location.no_turns') }}
        </div>
        <div v-else-if="!maxMore" class="text-center">
            <b-button variant="primary" class="mb-3" :disabled="isLoadingMore" @click="loadTurns">
                <b-spinner small v-if="isLoadingMore"></b-spinner>
                <span class="sr-only" v-if="isLoadingMore">Загрузка...</span>
                {{ trans('location.more') }}
            </b-button>
        </div>

    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Turn from "../../components/Turn";
    import MakeTurn from "../../components/MakeTurn";
    import {newTurnsSimpleMixin} from "@/mixins/newTurnsSimpleMixin";
    import GameGroupSwitch from "../../components/includes/GameGroupSwitch";

    export default {
        name: "LatestTurns",
        mixins: [
          newTurnsSimpleMixin
        ],
        props: {
            showLocation: { type: Boolean, default: false },
            withProfile: { type: Boolean, default: false },
        },
        components: {
            GameGroupSwitch,
            MakeTurn,
            Turn,
            FontAwesomeIcon
        },
        data() {
            return {
                turns: [],
                currentPage: 0,
                isLoadingMore: false,
                maxMore: false
            }
        },
        beforeDestroy() {
          // На всякий случай завершаем и тут
          this.leave();
        },
        computed: {
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            gameLocations () {
              return this.$store.getters['rpg/locations'];
            },

            isUserCharacter () {
              return this.$store.getters['rpg/isUserCharacter'];
            },

            /**
             * Нужно ли в принципе грузить новые сообщения
             * @returns {boolean}
             */
            needListenNewTurns() {
              return !!this.currentTurnChannel;
            },

            /**
             * API URL для загрузки новых ходов
             * @returns {string}
             */
            newTurnsApiUrl() {
              return '/api/rpg/turns/' + this.currentGame.key + '/new' + (this.turnsForProfile(this.currentCharacter) ? '/' + this.currentCharacter.key : '');
            }
        },
        created() {
            this.loadTurns();
        },
        watch: {
            currentGame (newGame, oldGame) {
                if (oldGame && oldGame.key) {
                    this.leave();
                }
                this.currentPage = 0;
                this.loadTurns();
            },
            currentCharacter (newCharacter, oldCharacter) {
              if (this.turnsForProfile(newCharacter) || this.turnsForProfile(oldCharacter)) {
                this.leave();
                this.currentPage = 0;
                this.loadTurns();
              }
            },
            turns () {
                if (this.turns.length && this.turns[0].location && this.gameLocations[this.turns[0].location] && this.gameLocations[this.turns[0].location].bgimage) {
                    this.$store.dispatch('rpg/setBackground', this.gameLocations[this.turns[0].location].bgimage);
                }
            }
        },
        methods: {
            loadTurns() {
                if (this.currentPage > 0) {
                  this.isLoadingMore = true
                } else {
                  this.location = null;
                  this.turns = [];
                  this.$store.dispatch('loading/start', 'Загрузка ходов...', { root: true });
                }

                this.axios.get('/api/rpg/turns/' + this.currentGame.key + this.linkGen())
                    .then((response) => {
                        var turns = response.data.turns;
                        // Иногда приходит не массив, а объект?
                        if (turns instanceof Object) {
                            turns = Object.values(turns);
                        }

                        if (!turns.length) {
                            this.maxMore = true;
                        }

                        this.addNewTurns(turns, false);

                        if (this.currentPage === 0) {
                          this.listen(this.currentGame.key);
                        }
                        this.currentPage++;
                    }).catch((error) => {
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                    })
                    .then(() => {
                        if (this.isLoadingMore) {
                            this.isLoadingMore = false;
                        } else {
                            this.$store.dispatch('loading/stop', null, { root: true });
                        }
                    });
            },

            trans (key) {
                return this.$t(key);
            },

            linkGen() {
              return (this.turnsForProfile(this.currentCharacter) ? '/' + this.currentCharacter.key : '') +
                     (this.currentPage < 1 ? '' : '?page=' + (this.currentPage + 1));
            },

            turnsForProfile (char) {
              return this.withProfile && char && char.key !== 'user' && char.key !== 'master' && char.location;
            },

            /**
             * Включить прослушивание сокетов для ходов игры.
             */
            listen(gameKey) {
                var newTurnChannel = null;

                if (this.turnsForProfile(this.currentCharacter)) {
                  let charKey = this.currentCharacter.key;
                  newTurnChannel = `rpg.game.${gameKey}.profile.${charKey}.turn`;
                } else {
                  newTurnChannel = `rpg.game.${gameKey}.turn`;
                }

                if (this.currentTurnChannel) {
                  if (this.currentTurnChannel === newTurnChannel) {
                    return;
                  } else {
                    this.leave();
                  }
                }
                this.currentTurnChannel = newTurnChannel;

                //console.log(`join ` + this.currentTurnChannel);
                Echo.channel(this.currentTurnChannel)
                    .listen('TurnSent', event => {
                      this.addTurn(event.turn);
                    })
                    .listen('TurnDeleted', event => {
                        this.deleteTurn(event.turn);
                    })
                    .listen('TurnUpdated', event => {
                        this.editTurn(event.turn);
                    });
                this.listenSimple();
            },

            addTurn(turn) {
                this.turns.splice(0, 0, turn);
            },

            editTurn(turn) {
                var turnIndex = this.turns.findIndex(currentTurn => currentTurn.id === turn.id);
                if (turnIndex !== -1) {
                    this.turns.splice(turnIndex, 1, turn);
                }
            },

            deleteTurn(turn) {
                var turnIndex = this.turns.findIndex(currentTurn => currentTurn.id === turn.id);
                if (turnIndex !== -1) {
                    this.turns.splice(turnIndex, 1);
                }
            },

            /**
             * Выключить прослушивание сокетов для игры.
             */
            leave() {
                //console.log(`leave ` + this.currentTurnChannel);
                Echo.leave(this.currentTurnChannel);
                this.leaveSimple();
                this.currentTurnChannel = null;
            },
        }
    }
</script>

<style scoped>
    .list-item {
        display: block;
    }
    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }
</style>
