<template>
    <div>
        <font-awesome-icon :icon="preparedMessage.icon" size="lg" fixed-width v-if="preparedMessage.icon.length"></font-awesome-icon>
        <span v-html="preparedMessage.message"/>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: "RollDiceMessage",
        components: {
            FontAwesomeIcon
        },
        props: {
            message: { required: true }
        },
        computed: {
            preparedMessage() {
                // Пока только кубик
                //^\[d([0-9]+)([+-]?)([0-9]*)\|([0-9]*)\(?([0-9]*)([+-]?)([0-9]*)\)\]$
                var icon = [],
                    msg = this.message,
                    matches = msg.match(/^\[(.*)\|(.*)\]$/);

                if (matches) {
                    var msgStart = matches[1],
                        msgEnd = matches[2],
                        startMatches = msgStart.match(/^([0-9]*)d([0-9]+)([+-]?)([0-9]*)$/),
                        endMatches = msgEnd.match(/^([-0-9]*)(([\(]?)([^\)]*)([\)]?))$/); // Тут может быть минус в результате

                    if (startMatches) {
                        var dices = '',
                            withMod = '';
                        dices = (startMatches[1] && startMatches[1] > 1 ? startMatches[1] + ' ' + this.trans('chat.dices') : this.trans('chat.dice')) + ' d' + startMatches[2];
                        if (startMatches[3] && startMatches[4]) {
                            withMod = ' ' + this.trans('chat.with_mod') + ' <span class="' + (startMatches[3] === '-' ? 'text-danger' : 'text-success') + '">' + startMatches[3] + startMatches[4] + '</span>';
                        }
                        msgStart = dices + withMod;
                    }

                    if (endMatches) {
                        msgEnd = '<b>' + endMatches[1] + '</b>' + (endMatches[2] ? ' ' + endMatches[2].replace(new RegExp(/\+/,'g'), " + ").replace("-", " - ") : '') // минус может быть только один
                    }

                    msg = this.trans('chat.roll') + ' ' + msgStart + ': ' + msgEnd;
                    icon = ['fal', 'dice-d20']
                }

                return { icon: icon, message: msg };
            }
        },
        methods: {
            trans (key) {
                return this.$t(key);
            },
        }
    }
</script>

<style scoped>

</style>
