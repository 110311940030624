<template>
    <div>
        <!-- Overlay for fixed sidebar -->
        <div class="sidebar-overlay" :class="{'active': value}" @click="closeSidebar"></div>
        <!-- Material sidebar -->
        <aside id="sidebar" class="sidebar sidebar-redwall sidebar-fixed-left" :class="{'open': value}" role="navigation">
            <!-- Sidebar header -->
            <div class="sidebar-header sidegradient">
                <!-- Top bar -->
                <div class="top-bar"></div>
                <!-- Sidebar toggle button -->
                <button type="button" class="sidebar-toggle" @click="closeSidebar">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" class="sidebar-icon"/>
                </button>

                <!-- Sidebar brand image -->
                <div class="sidebar-image" v-if="!isAuth">
                    <img :src="serverUrl + setting('defaultAvatar')">
                </div>
                <!-- Sidebar brand name -->
                <router-link class="sidebar-brand" :to="{ name: 'login' }" active-class="active" v-if="!isAuth" @click="closeSidebar">
                    {{ trans('navs.login') }}
                </router-link>

                <!-- Sidebar brand image -->
                <div class="sidebar-image" v-if="isAuth">
                    <img :src="user.avatar">
                </div>
                <!-- Sidebar brand name -->
                <a class="sidebar-brand dropdown-toggle"
                   :class="{'collapsed': showUserCollapse }"
                   :aria-expanded="showUserCollapse ? 'true' : 'false'"
                   aria-controls="profile-dropdown"
                   @click="showUserCollapse = !showUserCollapse"
                   v-if="isAuth">
                    {{ user.name }}
                </a>
            </div>


            <!-- Sidebar navigation -->
            <ul class="nav sidebar-nav flex-column">
                <b-collapse tag="ul" id="profile-dropdown" v-model="showUserCollapse" class="dropdown dropdown-menu" v-if="isAuth">
                    <li class="nav-item">
                        <a class="nav-link" :href="setting('urlCrowdfunding')">
                            <font-awesome-icon :icon="['far', 'money-bill-alt']" class="sidebar-icon" fixed-width></font-awesome-icon>
                            {{ trans('navs.crowdfunding') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/logout" @click.prevent="logout">
                            <font-awesome-icon :icon="['fas', 'sign-out']" class="sidebar-icon" fixed-width></font-awesome-icon>
                            {{ trans('navs.logout') }}
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                </b-collapse>

                <li class="nav-item" v-if="setting('urlAdmin')">
                    <a class="nav-link" :href="setting('urlAdmin')">
                        <font-awesome-icon :icon="['fas', 'lock']" class="sidebar-icon" fixed-width></font-awesome-icon>
                        {{ setting('titleAdmin') }}
                    </a>
                </li>
                <li class="dropdown-divider" v-if="setting('urlAdmin')"></li>

                <li class="nav-item" :class="{'active': $route.name === 'profile.index'}" v-if="currentCharacter && !isUserCharacter" @click="closeSidebar">
                    <router-link class="nav-link" :to="{ name: 'profile.index' }">
                        <font-awesome-icon :icon="['fas', 'user']" class="sidebar-icon" fixed-width></font-awesome-icon>
                        {{ trans('profile.menu') }}
                    </router-link>
                </li>
                <li class="nav-item" :class="{'active': $route.name === 'profile.create'}" v-else-if="currentGame && currentGame.profile_create" @click="closeSidebar">
                  <router-link class="nav-link" :to="{ name: 'profile.create' }">
                    <font-awesome-icon :icon="['fas', 'plus']" class="sidebar-icon" fixed-width></font-awesome-icon>
                    {{ trans('create.title') }}
                  </router-link>
                </li>
                <li class="nav-item" :class="{'active': $route.name === 'profiles.index'}" v-if="currentGame && currentGame.enable_profiles" @click="closeSidebar">
                    <router-link class="nav-link" :to="{ name: 'profiles.index' }">
                        <font-awesome-icon :icon="['fas', 'users']" class="sidebar-icon" fixed-width></font-awesome-icon>
                        {{ trans('profiles.title') }}
                    </router-link>
                </li>
                <li class="nav-item" :class="{'active': $route.name === 'map' || $route.name === 'location'}" v-if="currentGame && currentGame.enable_map" @click="closeSidebar">
                    <router-link class="nav-link" :to="{ name: 'map' }">
                        <font-awesome-icon :icon="['fas', 'map-signs']" class="sidebar-icon" fixed-width></font-awesome-icon>
                        {{ trans('map.title') }}
                    </router-link>
                </li>
                <li class="nav-item" :class="{'active': $route.name === 'chat' || $route.name === 'channel'}" v-if="channelsIsActive" @click="closeSidebar">
                    <router-link class="nav-link" :to="{ name: 'chat' }">
                        <font-awesome-icon :icon="['fas', 'comments']" class="sidebar-icon" fixed-width></font-awesome-icon>
                        {{ trans('chat.title') }}
                        <b-badge :variant="$route.name === 'chat' || $route.name === 'channel' ? 'secondary' : 'primary'" pill v-if="chatUnread">{{ chatUnread }}</b-badge>
                    </router-link>
                </li>
                <li class="nav-item" :class="{'active': $route.name === 'rules'}" v-if="currentGame && currentGame.rules" @click="closeSidebar">
                    <router-link class="nav-link" :to="{ name: 'rules' }">
                        <font-awesome-icon :icon="['far', 'scroll-old']" class="sidebar-icon" fixed-width></font-awesome-icon>
                        {{ trans('game.rules') }}
                    </router-link>
                </li>

                <li class="dropdown-divider"></li>


                <li class="nav-item" :class="{'active': currentGame && gameKey === currentGame.key}" v-for="(game, gameKey) in games" @click="closeSidebar" v-if="game.is_show_menu">
                    <router-link class="nav-link" :to="{ name: 'main', params: { game: gameKey } }">
                        <font-awesome-icon :icon="[game.icon.type, game.icon.icon]" class="sidebar-icon" fixed-width v-if="game.icon"></font-awesome-icon>
                        {{ game.menu_title }}
                    </router-link>
                </li>

                <li class="dropdown-divider"></li>

                <li class="nav-item" v-for="menuItem in setting('menu')">
                    <a class="nav-link" :href="menuItem.link">
                        <font-awesome-icon :icon="[menuItem.icon.type, menuItem.icon.icon]" class="sidebar-icon" fixed-width v-if="menuItem.icon"></font-awesome-icon>
                          {{ menuItem.title }}
                    </a>
                </li>
            </ul>
        </aside>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


    export default {
        name: "Sidebar",
        components: {
            FontAwesomeIcon
        },
        props: {
            value: { required: true }
        },
        data() {
            return {
                showUserCollapse: false
            }
        },
        computed: {
            user () {
                return this.$store.getters['auth/getUser'];
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
            games () {
                return this.$store.getters['rpg/games'];
            },
            channelsIsActive() {
                return this.$store.getters['chat/channelsIsActive'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            isUserCharacter () {
                return this.$store.getters['rpg/isUserCharacter'];
            },
            serverUrl () {
                return this.$store.getters['rpg/serverUrl'];
            },
            chatUnread () {
                return this.$store.getters['chat/unreadCount'];
            }
        },
        methods: {
            trans (key) {
                return this.$t(key);
            },
            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },
            // setCurrentGame (key) {
            //     //Переходим на роут
            //     console.log(this.$router);
            //     this.$router.push({ name: 'main', params: { game: key } });
            //     //this.$store.dispatch('rpg/setGame', key);
            //     this.closeSidebar();
            // },
            closeSidebar () {
                this.$emit('input', false);
            },
            logout () {
                this.$store.dispatch('auth/logout');
            }
        }
    }
</script>

<style scoped>

</style>
