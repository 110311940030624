<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon :icon="['far', 'scroll-old']" size="2x"></font-awesome-icon></span>
            <div class="lh-100 flex-grow-1">
                <h4 class="mb-0 redfont lh-100">{{ trans('game.rules') }}</h4>
            </div>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow" v-if="currentGame" v-html="currentGame.rules"/>
    </div>
</template>

<script>
    import Breadcrumb from "../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


    export default {
        name: "Rules",
        components: {
            FontAwesomeIcon,
            Breadcrumb
        },
        computed: {
            breadcrumb() {
                return [
                    {route: {name: 'main'}, title: this.trans('title')},
                    {title: this.trans('game.rules')}
                ];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },
        },
        methods: {
            trans(key) {
                return this.$t(key);
            }
        }
    }
</script>

<style scoped>

</style>
