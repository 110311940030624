<template>
    <vue-draggable-resizable v-bind="$props" :z="vOrder" v-if="isActive" @activated="onActivated" class="panel-card" v-on="$listeners">
        <slot></slot>
    </vue-draggable-resizable>
</template>

<script>
    // https://github.com/mauricius/vue-draggable-resizable
    // Компонент для создания перемещаемых элементов
    import VueDraggableResizable from 'vue-draggable-resizable';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: "DraggablePanel",
        components: {
            VueDraggableResizable,
            FontAwesomeIcon
        },
        // Прокидываю все входные парарметеры
        // Вообще так не рекомендуется, но тут добавляется всего пара параметров, которых нет в
        // дочернем компоненте, плюс значения по умолчанию
        props: {
            ...VueDraggableResizable.props,
            // По умолчанию
            w: { default: 300 },
            h: { default: 300 },
            minWidth: { default: 100 },
            minHeight: { default: 100 },
            parent: { default: true },
            handles: { default: () => ['br'] },
            // Новые
            title: { default: ''},
            code: { default: null },
            isActive: { default: false }
        },
        // data() {
        //     return {
        //         isActive: true
        //     }
        // },
        computed: {
            operation() {
                return null;
                //return this.$store.getters['operations/byCode'](this.code);
            },
            // isActive() {
            //     return this.operation ? this.operation.active : false;
            // },
            header() {
                return 'Test';//this.operation && !this.title ? this.operation.title : this.title;
            },
            vOrder() {
                return 100;//return this.operation && this.operation.vOrder ? this.operation.vOrder : 1;
            }
        },
        methods: {
            // closePanel() {
            //     this.isActive = false;
            //     //this.$store.dispatch('operations/deactivate', { code: this.code });
            // },
            onActivated() {
                //this.$store.dispatch('operations/moveUp', { code: this.code });
            }
        }
    }
</script>

<style scoped>

</style>
