<template>
    <div>
        <img :src="currentGame.image" v-if="currentGame.image" class="img-thumbnail d-block mx-auto mb-2">

        <div v-html="currentGame.body"/>

        <div v-if="currentGame && currentGame.status !== 'closed' && isAuth" class="mt-2 mb-2 d-flex justify-content-center">
<!--            <router-link class="btn btn-primary" :to="{ name: 'channel', params: { channelType: 'rpg_game', channelId: currentGame.key } }"  role="button" :title="trans('game.on_feast')" v-if="currentCharacter && channelsIsActive && currentGame.status === 'holiday' && findChannel('rpg_game', currentGame.key)">-->
<!--                {{ trans('game.on_feast') }}-->
<!--            </router-link>-->

            <button class="btn btn-primary ml-2 mr-2" role="button" v-if="currentGame.profile_choose && !currentCharacter" @click="rulesConfirm('profiles.choose')">
                {{ trans('profiles.choose') }}
            </button>
        </div>

        <div v-else-if="currentGame && currentGame.status !== 'closed'" class="mt-2 mb-2 d-flex justify-content-center">
            <router-link class="btn btn-primary" :to="{ name: 'login' }" role="button">
                {{ trans('navs.login') }}
            </router-link>
        </div>

        <b-card class="card-turn pt-3 pl-3 pr-3 bg-rpg-light rounded box-shadow" v-html="currentGame.info" v-if="currentGame.info"></b-card>

        <quest-list-main v-if="currentGame.main_page_type === 'quests_chat'"></quest-list-main>
        <latest-turns v-if="currentGame.main_page_type === 'turns' || (currentGame.main_page_type === 'profile_turns' && isCharactersLoaded)" show-location :with-profile="currentGame.main_page_type === 'profile_turns'"></latest-turns>

        <side-online></side-online>
    </div>
</template>

<script>
    import QuestListMain from "./Quest/includes/QuestListMain";
    import LatestTurns from "./includes/LatestTurns";
    import SideOnline from "../components/includes/SideOnline";
    export default {
        name: "Main",
        components: {SideOnline, LatestTurns, QuestListMain},
        computed: {
            currentGame () {
                return this.$store.getters['rpg/currentGame'] ? this.$store.getters['rpg/currentGame'] : {};
            },
            isAuth () {
                return this.$store.getters['auth/isAuth'];
            },
            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },
            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },
            channelsIsActive() {
                return this.$store.getters['chat/channelsIsActive'];
            },
            channels () {
                return this.$store.getters['chat/channels'];
            },
            isCharactersLoaded() {
              return this.$store.getters['rpg/isCharactersLoaded'];
            },
        },
        methods: {
            findChannel (type, id) {
                return this.$store.getters['chat/findChannel']({ type: type, id: id });
            },

            trans(key) {
                return this.$t(key);
            },

            setting (key) {
                return this.$store.getters['settings/setting'](key);
            },

            /**
             * Проверяет, открыто ли окно чата выбранного канала.
             * @param channel
             * @returns {channel|{}|boolean}
             */
            isWindowChannel(channel) {
                return this.$store.getters['chat/isWindowChannel'](channel);
            },

            rulesConfirm(route) {
                // Если правил нет, то и ладно
                if (!this.currentGame.rules) {
                    this.$router.push({ name: route })
                }

                const h = this.$createElement;
                // Using HTML string
                const messageVNode = h('div', { domProps: { innerHTML: this.currentGame.rules } });

                this.$bvModal.msgBoxConfirm([messageVNode], {
                    title: this.trans('game.confirm_rules'),
                    okTitle: this.trans('game.agree'),
                    cancelTitle: this.trans('game.not_agree'),
                    centered: true
                }).then(value => {
                    if (value) {
                        this.$router.push({ name: route })
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>

