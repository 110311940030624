<template>
    <div>
        <div class="d-flex align-items-center p-3 my-3 text-rpg-light bg-rpg-primary rounded box-shadow">
            <span class="mr-3"><font-awesome-icon icon="users" size="2x"></font-awesome-icon></span>
            <div class="lh-100">
                <h4 class="mb-0 redfont lh-100">{{ trans('profiles.choose') }}</h4>
            </div>
        </div>

        <breadcrumb :items="breadcrumb"></breadcrumb>

        <div class="my-3 p-3 bg-rpg-light rounded box-shadow">

            <div class="container" v-if="!faction && factions">
                <div class="row">
                    <div :class="factions && Object.keys(factions).length > 2 ? 'col' : ['col-md-8', 'offset-md-2']">
                        <b-card-group deck>
                            <b-card :img-src="faction.image" :img-alt="faction.title" img-top v-for="faction in factions" :key="faction.key" class="card-choose" @click="chooseFaction(faction.key)">
                                <b-card-title v-html="faction.colorTitle"/>
                                <b-card-text v-html="faction.desc"/>
                            </b-card>
                        </b-card-group>
                    </div>
                </div>
            </div>

            <b-card-group columns v-else-if="factionProfiles.length">
                <b-card :title="profile.name" :img-src="profile.image" :img-alt="profile.name" img-top v-for="profile in factionProfiles" :key="profile.id" :class="profile.choosed ? 'card-choose-disabled' : 'card-choose'" @click="chooseConfirm(profile)">
                    <b-card-sub-title class="mb-2" v-if="profile.positionTitle || profile.speciesTitle">{{ profile.speciesTitle }}{{ profile.positionTitle ? (profile.speciesTitle ? ' · ' : '') + profile.positionTitle : '' }}{{ profile.ageInfo && profile.ageInfo !== trans('age.unknown')  ? ' · ' + profile.ageInfo : '' }}</b-card-sub-title>
                    <b-card-text v-html="profile.biography"/>
                </b-card>
            </b-card-group>

            <span v-else>{{ trans('profile.no_profiles') }}</span>

        </div>

    </div>
</template>

<script>
    import Breadcrumb from "../../components/includes/Breadcrumb";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: "ProfilesChoose",
        components: {
            FontAwesomeIcon,
            Breadcrumb
        },
        data() {
            return {
                profiles: [],
                factions: {},
                faction: null,
                lastPage: 0
            }
        },
        computed: {
            breadcrumb() {
                return [
                    { route: { name: 'main' }, title: this.trans('title')},
                    { title: this.trans('profiles.choose')}
                ];
            },

            currentGame () {
                return this.$store.getters['rpg/currentGame'];
            },

            currentCharacter () {
                return this.$store.getters['rpg/currentCharacter'];
            },

            isMaster () {
                return this.$store.getters['rpg/isMaster'];
            },

            choosedFaction() {
                return this.faction && Object.keys(this.factions).indexOf(this.faction) !== -1 ? this.factions[this.faction] : null;
            },

            factionProfiles() {
                return this.choosedFaction && this.profiles ? this.profiles.filter((profile) => {
                    return profile.faction === this.faction;
                }) : [];
            }
        },
        created() {
            this.loadProfiles();
        },
        watch: {
            currentGame(newGame, oldGame) {
                this.loadProfiles();
            },
            // Пока не следим - в зависимости от того, приходит ли вся инфа, нужная мастеру
            //currentCharacter(newCharacter, oldCharacter) {
            //this.loadProfiles();
            //}
        },
        // beforeRouteUpdate (to, from, next) {
        //     // обрабатываем изменение параметров маршрута...
        //     // не забываем вызвать next()
        //     this.loadProfiles();
        //     next();
        // },
        methods: {
            hasCharacter (key) {
                return this.$store.getters['rpg/hasCharacter'](key);
            },
            loadProfiles() {
                //console.log(this.$route);

                this.$store.dispatch('loading/start', 'Загрузка персонажей...', { root: true });

                this.axios.get('/api/rpg/profiles/' + this.currentGame.key + '/choose')
                    .then((response) => {
                        if (response.data) {
                            this.profiles = response.data.profiles;
                            this.factions = response.data.factions;
                        }
                        this.$store.dispatch('loading/stop');
                    })
                    .catch((error) => {
                        this.$store.dispatch('loading/stop');
                        this.$router.push({ name: 'main' });
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                        this.$store.dispatch('loading/stop', null);

                        // context.rootState.router.push('login');
                    });
            },
            trans (key) {
                return this.$t(key);
            },

            chooseFaction(key) {
                this.faction = key;
            },

            chooseProfile(profile) {
                if (profile.choosed) {
                    return;
                }

                this.$store.dispatch('loading/start', 'Выбор персонажа...', { root: true });

                this.axios.get('/api/rpg/profiles/' + this.currentGame.key + '/choose/' + profile.id + '-' + profile.seo)
                    .then((response) => {
                        this.$store.dispatch('loading/stop');
                        this.profileChoosed();
                    })
                    .catch((error) => {
                        this.$store.dispatch('loading/stop');
                        let message = error.response.data.error ? error.response.data.error : error.response.data;
                        this.$store.dispatch('loading/error', message);
                    });
            },

            profileChoosed() {
                // Перезагрузить список персонажей
                this.$store.dispatch('rpg/loadCharacters');
                // Перейти на главную
                // возможно как колбэк после загрузки персонажей?
                this.$router.push({ name: 'main' })
            },

            chooseConfirm(profile) {
                if (profile.choosed) {
                    return;
                }

                const h = this.$createElement;
                // Using HTML string
                const messageVNode = h('div', { domProps: { innerHTML: 'Вы уверены, что хотите выбрать персонажа <b>' + profile.name + '</b>? Вы не сможете поменять персонажа без мастера.' } });

                this.$bvModal.msgBoxConfirm([messageVNode], {
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    centered: true
                }).then(value => {
                    if (value) {
                        this.chooseProfile(profile);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
