<template>
    <div class="row">
        <transition name="fade" mode="out-in">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item" :class="{'active': !!item.route || !!item.link}" v-for="(item, index) in items" :key="index">
                    <router-link :to="item.route" v-if="item.route">
                        {{ item.title }}
                    </router-link>
                    <a :href="item.link" v-else-if="item.link">
                        {{ item.title }}
                    </a>
                    <span v-else>
                    {{ item.title }}
                </span>
                </li>
            </ol>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Breadcrumb",
        props: {
            items: { default: null }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>
